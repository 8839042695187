import clsx from 'clsx'
import { BreakpointType } from '../../atoms/BreakpointDropdown/BreakpointDropdown'
import { HeaderDropdown } from '../../atoms/HeaderDropdown/HeaderDropdown'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { useFormContext } from '../../form/context/FormContext'
import {
  BackgroundType,
  RHFBackgroundSettingsPropType,
} from './BackgroundSettings'
import { BreakpointBackgroundFields } from './components/BreakpointBackgroundFields'
import { resolveActiveBackgrounds } from './helpers'
import { Icon } from '@sceneio/ui-icons'

export const DefaultBackgroundSettings = ({
  basePath,
  className,
  title = 'Background',
  options = ['color', 'image', 'overlay', 'blur'],
  onMediaAddClick,
}: RHFBackgroundSettingsPropType) => {
  const { values, register, registerValues } = useFormContext({
    name: basePath,
  })
  const valuesPath = basePath || ''
  const hasExistingValues = Object.keys(values).some((val) =>
    val.includes('responsiveConfig'),
  )

  const onBackgroundTypeSelect = ({
    breakpoint,
    type,
  }: {
    breakpoint: BreakpointType | 'default'
    type: BackgroundType
  }) => {
    if (type === 'color' || type === 'overlay') {
      register({
        [`${valuesPath}.responsiveConfig.${breakpoint}.${type}`]:
          registerValues[`${valuesPath}.responsiveConfig.default.${type}`],
      })
    }
    if (type === 'image') {
      register({
        [`${valuesPath}.responsiveConfig.${breakpoint}.image.src`]:
          registerValues[`${valuesPath}.responsiveConfig.default.image.src`],
        [`${valuesPath}.responsiveConfig.${breakpoint}.image.alt`]:
          registerValues[`${valuesPath}.responsiveConfig.default.image.alt`],
        [`${valuesPath}.fit`]: registerValues[`${valuesPath}.fit`],
        [`${valuesPath}.parallax`]: registerValues[`${valuesPath}.parallax`],
      })
    }
    if (type === 'blur') {
      register({
        [`${valuesPath}.responsiveConfig.${breakpoint}.filter.blur`]:
          registerValues[`${valuesPath}.responsiveConfig.default.filter.blur`],
      })
    }
  }

  return (
    <SegmentWrapper className={clsx('sc-rhf-spacing-settings', className)}>
      <HeaderSegment
        heading={title}
        customChildren={
          <>
            {options.length === 1 ? (
              <button
                type="button"
                className={clsx('tw-p-1', { 'tw-hidden': hasExistingValues })}
                onClick={() => {
                  onBackgroundTypeSelect({
                    breakpoint: 'default',
                    type: options[0] as BackgroundType,
                  })
                }}
              >
                <Icon provider="phosphor" icon={'Plus'} />
              </button>
            ) : (
              <HeaderDropdown
                options={options}
                disabledOptions={resolveActiveBackgrounds({
                  values,
                  valuesPath,
                  breakpoint: 'default',
                })}
                onSelect={(type) => {
                  onBackgroundTypeSelect({
                    breakpoint: 'default',
                    type: type as BackgroundType,
                  })
                }}
              />
            )}
          </>
        }
      />
      {hasExistingValues && (
        <BreakpointBackgroundFields
          breakpoint={'default'}
          valuesPath={valuesPath}
          onMediaAddClick={onMediaAddClick}
        />
      )}
    </SegmentWrapper>
  )
}
