import React from 'react'
import { heroCountdownDefaultConfig } from './heroCountdownMeta'
import HeroCountdown from './HeroCountdown'

const HeroCountdownPreview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : heroCountdownDefaultConfig

  return <HeroCountdown {...defaultData} />
}

export default HeroCountdownPreview
