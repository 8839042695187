import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { galleryCarouselDefaultConfig } from './galleryCarouselMeta'
import Text from '../../../components/Text/Text'
import type { GalleryCarouselBlock } from '@sceneio/schemas/lib/types'
import GalleryItem from '../../../components/GalleryItem/GalleryItem'
import { removeNil } from '@sceneio/tools'
import { lexicalToPlainText } from '@sceneio/wysiwyg-utils/lib/lexicalToPlainText'
import SliderVariant2 from '../../../components/Slider/SliderVariant2/SliderVariant2'

function GalleryCarousel({
  id,
  components: { block, heading, text, slider },
  preview,
  editor,
}: GalleryCarouselBlock = galleryCarouselDefaultConfig) {
  const { className, ...restBlockProps } = block || {}

  const galleryClass = clsx('sc-gallery-carousel', className)

  const containsLightboxImages = slider?.components?.slides.items?.some(
    (slide) => slide?.components?.image?.link?.type === 'lightbox',
  )

  return (
    <Block
      className={galleryClass}
      id={id}
      editor={editor}
      dataAttributes={{ 'data-plugin-image-lightbox': containsLightboxImages }}
      {...restBlockProps}
    >
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <SliderVariant2
        {...slider}
        preview={preview}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'slider',
        }}
        className="sc-gallery-carousel__slider"
        components={{
          ...slider.components,
          slides: {
            ...slider.components.slides,
            items: slider.components.slides.items.map((slide, idx) => {
              return (
                <GalleryItem
                  {...slide}
                  key={slide.id}
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `slider.slides.items[${idx}]`,
                  }}
                  components={{
                    ...slide.components,
                    image: {
                      ...slide.components?.image,
                      dataAttributes: {
                        ...(slide.components?.image?.link?.type ===
                          'lightbox' && {
                          'data-plugin-image-lightbox-image': JSON.stringify(
                            removeNil({
                              heading: slide.components.heading?.show
                                ? lexicalToPlainText(
                                    slide.components.heading?.children!,
                                  )
                                : undefined,
                              description: slide.components.description?.show
                                ? lexicalToPlainText(
                                    slide.components.description?.children!,
                                  )
                                : undefined,
                            }),
                          ),
                        }),
                      },
                    },
                  }}
                  cid={resolveComponentId(id, `slider.slides.items[${idx}]`)}
                  className="sc-gallery-grid__item"
                />
              )
            }),
          },
        }}
      />
    </Block>
  )
}

export const galleryCarouselPlugins = {
  js: ['animation', 'imageLightbox', 'slider'],
  css: ['imageLightbox', 'slider'],
}
export default GalleryCarousel
