import React from 'react'
import { CountdownComponent } from '@sceneio/schemas/lib/types'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { Container } from '../../Container/Container'
import Text from '../../Text/Text'

export const CountdownItem = ({
  editor,
  item,
  dots,
  id,
  cid,
}: {
  editor: CountdownComponent['editor']
  item: CountdownComponent['components']['days']
  dots: CountdownComponent['components']['dots']
  id: string
  cid?: string
}) => {
  return (
    <>
      <Container
        editor={{
          ...editor,
          id: `${editor?.id}.${id}`,
        }}
        {...item}
        name="group"
        className="sc-countdown__item"
      >
        <Text
          {...item.components.text}
          editor={{
            ...editor,
            id: `${editor?.id}.${id}.text`,
            wysiwyg: {
              type: 'RAW',
            },
          }}
          cid={resolveComponentId(cid, `${id}.text`)}
          dataAttributes={{
            [`data-plugin-countdown-${id}`]: true,
          }}
          className="sc-countdown__item-text"
        >
          01
        </Text>
        <Text
          {...item.components.label}
          editor={{
            ...editor,
            id: `${editor?.id}.${id}.label`,
          }}
          cid={resolveComponentId(cid, `${id}.label`)}
        />
      </Container>
      {item.show && id !== 'seconds' && (
        <Text
          {...dots}
          editor={{
            ...editor,
            id: `${editor?.id}.dots`,
            wysiwyg: {
              type: 'RAW',
            },
          }}
          cid={resolveComponentId(cid, 'dots')}
        >
          :
        </Text>
      )}
    </>
  )
}
