import { useState, useRef } from 'react'
import { LexicalEditor } from 'lexical'
import { $getSelection, $INTERNAL_isPointSelection } from 'lexical'
import { $patchStyleText } from '@lexical/selection'
import {
  Combobox,
  ComboboxGroup,
  ComboboxList,
  ComboboxProvider,
  Select,
  SelectPopover,
  SelectProvider,
} from '@ariakit/react'
import { Icon } from '@sceneio/ui-icons'
import { fontsConfig } from '@sceneio/config-fonts'
import { normalizeFonts } from '@sceneio/ui-forms/lib/components/atoms/FontSelect/FontSelect'

const fontOptions = normalizeFonts(Object.values(fontsConfig))

interface ToolbarFontSelectProps {
  editor: LexicalEditor
  fontFamily: string
}

export function ToolbarFontSelect({
  editor,
  fontFamily,
}: ToolbarFontSelectProps) {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const ref = useRef<HTMLDivElement>(null)

  const handleFontChange = (newValue: string) => {
    editor.update(() => {
      const selection = $getSelection()
      if ($INTERNAL_isPointSelection(selection)) {
        $patchStyleText(selection, {
          'font-family': newValue,
        })
      }
    })
    setOpen(false)
  }

  const filterFontsBySearchQuery = () => {
    return query === ''
      ? fontOptions
      : fontOptions.filter((item) =>
          item.title
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        )
  }

  const selectedFont = fontOptions.find((option) => option.value === fontFamily)

  return (
    <div className="wysiwyg-toolbar-toggle-group" ref={ref}>
      <ComboboxProvider
        open={open}
        setOpen={setOpen}
        resetValueOnHide
        setValue={setQuery}
      >
        <SelectProvider
          open={open}
          value={fontFamily || ''}
          setValue={handleFontChange}
        >
          <Select className="wysiwyg-toolbar-toggle-item wysiwyg-toolbar-toggle-item--font-family">
            <span
              className="wysiwyg-select__selected-font"
              style={{ fontFamily: fontFamily || 'inherit' }}
            >
              {selectedFont?.title || 'Default'}
            </span>
            <Icon
              provider="phosphor"
              icon="CaretDown"
              weight="bold"
              size={8}
              className="tw-shrink-0"
            />
          </Select>
          <SelectPopover
            sameWidth
            className="wysiwyg-select-content wysiwyg-select-content--font-family"
          >
            {open && (
              <>
                <div className="wysiwyg-select__search-container">
                  <Icon provider="phosphor" icon="MagnifyingGlass" size={14} />
                  <Combobox
                    autoSelect
                    placeholder="Search fonts..."
                    className="wysiwyg-select__search-input"
                    onBlurCapture={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                    }}
                    setValueOnChange
                  />
                </div>
                <ComboboxList
                  onWheelCapture={(e) => {
                    if (Math.abs(e.deltaY) > Math.abs(e.deltaX) * 1.5) {
                      e.stopPropagation()
                    }
                  }}
                  className="wysiwyg-select__list"
                >
                  <ComboboxGroup>
                    {filterFontsBySearchQuery().map((font) => (
                      <div
                        key={font.value}
                        role="option"
                        data-selected={font.value === fontFamily}
                        className="wysiwyg-select__option"
                        onClick={() => handleFontChange(font.value)}
                      >
                        <div className="wysiwyg-select__option-text">
                          {font.label}
                        </div>
                      </div>
                    ))}
                  </ComboboxGroup>
                </ComboboxList>
              </>
            )}
          </SelectPopover>
        </SelectProvider>
      </ComboboxProvider>
    </div>
  )
}
