import * as Select from '@radix-ui/react-select'
import { Icon } from '@sceneio/ui-icons'
import { TagSelectItem } from './TagSelectItem'
import { LexicalEditor } from 'lexical'
import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text'
import { $createParagraphNode, $getSelection } from 'lexical'
import { $INTERNAL_isPointSelection } from 'lexical'
import { $setBlocksType } from '@lexical/selection'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'

interface ToolbarTagSelectProps {
  editor: LexicalEditor
  blockType: string
  enableList?: boolean
}

export function ToolbarTagSelect({
  editor,
  blockType,
  enableList,
}: ToolbarTagSelectProps) {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($INTERNAL_isPointSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        if ($INTERNAL_isPointSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize))
        }
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const handleFormatChange = (value: string) => {
    if (value === 'paragraph') {
      formatParagraph()
    }

    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)) {
      formatHeading(value as HeadingTagType)
    }

    if (value === 'bullet') {
      formatBulletList()
    }

    if (value === 'number') {
      formatNumberedList()
    }
  }

  return (
    <div className="wysiwyg-toolbar-toggle-group">
      <Select.Root
        onOpenChange={(open) => {
          if (open) {
            // fix radix select disabling pointer events on body, preventing moving in infinite canvas
            setTimeout(() => {
              document.body.style.pointerEvents = 'auto'
            }, 1)
          }
        }}
        defaultValue={blockType}
        onValueChange={handleFormatChange}
      >
        <Select.Trigger className="wysiwyg-tag-select-trigger">
          <Select.Value placeholder="Select format" />
          <Select.Icon className="wysiwyg-tag-select-icon">
            <Icon provider="phosphor" icon="CaretDown" weight="bold" size={8} />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content
          position="popper"
          className="wysiwyg-tag-select-content"
        >
          <Select.Viewport className="wysiwyg-tag-select-viewport">
            <Select.Group>
              <TagSelectItem
                value="paragraph"
                active={blockType === 'paragraph'}
              >
                <Icon
                  size={16}
                  provider="phosphor"
                  weight="bold"
                  icon="TextAlignJustify"
                />
              </TagSelectItem>
              <TagSelectItem value="h1" active={blockType === 'h1'}>
                <Icon
                  size={16}
                  provider="phosphor"
                  weight="bold"
                  icon="TextHOne"
                />
              </TagSelectItem>
              <TagSelectItem value="h2" active={blockType === 'h2'}>
                <Icon
                  size={16}
                  provider="phosphor"
                  weight="bold"
                  icon="TextHTwo"
                />
              </TagSelectItem>
              <TagSelectItem value="h3" active={blockType === 'h3'}>
                <Icon
                  size={16}
                  provider="phosphor"
                  weight="bold"
                  icon="TextHThree"
                />
              </TagSelectItem>
              <TagSelectItem value="h4" active={blockType === 'h4'}>
                <Icon
                  size={16}
                  provider="phosphor"
                  weight="bold"
                  icon="TextHFour"
                />
              </TagSelectItem>
              <TagSelectItem value="h5" active={blockType === 'h5'}>
                <Icon
                  size={16}
                  provider="phosphor"
                  weight="bold"
                  icon="TextHFive"
                />
              </TagSelectItem>
              <TagSelectItem value="h6" active={blockType === 'h6'}>
                <Icon
                  size={16}
                  provider="phosphor"
                  weight="bold"
                  icon="TextHSix"
                />
              </TagSelectItem>
              {enableList && (
                <>
                  <TagSelectItem value="bullet" active={blockType === 'bullet'}>
                    <Icon
                      size={16}
                      provider="phosphor"
                      weight="bold"
                      icon="ListBullets"
                    />
                  </TagSelectItem>
                  <TagSelectItem value="number" active={blockType === 'number'}>
                    <Icon
                      size={16}
                      provider="phosphor"
                      weight="bold"
                      icon="ListNumbers"
                    />
                  </TagSelectItem>
                </>
              )}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Root>
    </div>
  )
}
