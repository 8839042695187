import { InferType, array, bool, object, string } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { imageComponentSchema } from '../../components/imageComponentSchema'
import { version } from '../../shared/versionSchema'
import { textComponentSchema } from '../../components/textComponentSchema'

const headerMenuItemSchema = buttonComponentSchema
  .shape({
    id: idSchema.required(),
  })
  .noUnknown()

const itemsDisplayTargetSchema = object({
  xs: string().oneOf(['mobile', 'desktop']),
  md: string().oneOf(['mobile', 'desktop']),
  lg: string().oneOf(['mobile', 'desktop']),
}).noUnknown()

const headerMenuSchema = containerComponentSchema
  .shape({
    items: array().of(
      headerMenuItemSchema.shape({
        items: array().of(headerMenuItemSchema),
      }),
    ),
    displayTarget: itemsDisplayTargetSchema,
  })
  .noUnknown()

export const headerVariant1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        position: string().oneOf(['sticky', 'fixed', 'relative']).optional(),
        hideOnScroll: bool(),
      })
      .noUnknown(),
    logo: imageComponentSchema,
    menu: headerMenuSchema,
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
        displayTarget: itemsDisplayTargetSchema,
      })
      .noUnknown(),
    toggler: buttonComponentSchema,
    topBanner: containerComponentSchema
      .shape({
        items: array().of(
          textComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type ItemsDisplayTarget = InferType<typeof itemsDisplayTargetSchema>
export type HeaderVariant1Block = InferType<typeof headerVariant1BlockSchema>
