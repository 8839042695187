import React from 'react'
import { fontsConfig } from '@sceneio/config-fonts'
import { SearchableFontSelect } from './components/SearchableFontSelect'
import { useFormContext } from '../../form/context/FormContext'
import type { FontType, FontsMap } from '@sceneio/config-fonts/lib/fonts'
import { fontsPreviewSpriteYCoordsByFontFamily } from '@sceneio/config-fonts/lib/fonts'

export type RHFFontSelectProps = {
  name: string
  isClearable?: boolean
}

export const FontOptionLabel = ({
  title,
  spriteYCoord,
}: {
  title: string
  spriteYCoord: number
}) => {
  if (!spriteYCoord) {
    return (
      <div className="tw-inline-flex tw-items-center tw-h-[17px]">{title}</div>
    )
  }
  return (
    <div
      className="tw-h-[17px] tw-w-[157px]"
      style={{
        backgroundImage: `url('https://assets.scene.io/global/fonts/fonts-preview-sprite.svg?v=1733144081446')`,
        backgroundSize: '157px 1411px',
        backgroundRepeat: 'repeat-y',
        backgroundPosition: `0 ${spriteYCoord}px`,
      }}
    />
  )
}

export const normalizeFonts = (
  fonts: FontType[] = [],
  customFonts: FontsMap = {},
) => {
  return fonts
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((value) => ({
      value: value.fontFamily,
      label: (
        <FontOptionLabel
          title={value.title}
          spriteYCoord={
            fontsPreviewSpriteYCoordsByFontFamily[
              value.fontFamily as keyof typeof fontsPreviewSpriteYCoordsByFontFamily
            ]
          }
        />
      ),
      title: value.title,
      uploaded: Object.values(customFonts || {}).some(
        (f: any) => f.title === value.title,
      ),
    }))
}

export const RHFFontSelect = ({ name }: RHFFontSelectProps) => {
  const { customFonts, usedFonts, onUploadFontClick } = useFormContext({ name })
  const normalizedFontFamilyOptions = normalizeFonts(
    Object.values({ ...fontsConfig, ...customFonts }),
    customFonts || {},
  )

  return (
    <SearchableFontSelect
      name={name}
      fonts={normalizedFontFamilyOptions}
      onUploadFontClick={onUploadFontClick}
      usedFonts={normalizeFonts(usedFonts, customFonts)}
    />
  )
}
