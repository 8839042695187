import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { galleryGridDefaultConfig } from './galleryGridMeta'
import Text from '../../../components/Text/Text'
import Grid from '../../../components/Grid/Grid'
import type { GalleryGridBlock } from '@sceneio/schemas/lib/types'
import GalleryItem from '../../../components/GalleryItem/GalleryItem'
import { removeNil } from '@sceneio/tools'
import { lexicalToPlainText } from '@sceneio/wysiwyg-utils/lib/lexicalToPlainText'

function GalleryGrid({
  id,
  components: { block, heading, text, galleryItems },
  editor,
}: GalleryGridBlock = galleryGridDefaultConfig) {
  const { items } = galleryItems || {}
  const { className, ...restBlockProps } = block || {}

  const galleryClass = clsx('sc-gallery-grid', className)

  const containsLightboxImages = galleryItems?.items?.some(
    (galleryItem) => galleryItem?.components?.image?.link?.type === 'lightbox',
  )

  return (
    <Block
      className={galleryClass}
      id={id}
      editor={editor}
      dataAttributes={{ 'data-plugin-image-lightbox': containsLightboxImages }}
      {...restBlockProps}
    >
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <Grid
        {...galleryItems}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'galleryItems',
        }}
        items={items?.map((item, index) => (
          <GalleryItem
            {...item}
            key={item.id}
            editor={{
              ...editor?.component,
              blockId: id,
              id: `galleryItems.items[${index}]`,
            }}
            components={{
              ...item.components,
              image: {
                ...item.components?.image,
                dataAttributes: {
                  ...(item.components?.image?.link?.type === 'lightbox' && {
                    'data-plugin-image-lightbox-image': JSON.stringify(
                      removeNil({
                        heading: item.components.heading?.show
                          ? lexicalToPlainText(
                              item.components.heading?.children!,
                            )
                          : undefined,
                        description: item.components.description?.show
                          ? lexicalToPlainText(
                              item.components.description?.children!,
                            )
                          : undefined,
                      }),
                    ),
                  }),
                },
              },
            }}
            cid={resolveComponentId(id, `galleryItems.items[${index}]`)}
            className="sc-gallery-grid__item"
          />
        ))}
      />
    </Block>
  )
}

export const galleryGridPlugins = {
  js: ['animation', 'imageLightbox'],
  css: ['imageLightbox'],
}
export default GalleryGrid
