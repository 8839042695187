import { useCallback, useEffect, useRef, useState } from 'react'
import { LexicalEditor } from 'lexical'
import { $getSelection, $INTERNAL_isPointSelection } from 'lexical'
import { $patchStyleText } from '@lexical/selection'
import ColorPicker from 'react-best-gradient-color-picker'
import * as Toolbar from '@radix-ui/react-toolbar'
import useOnClickOutside from 'react-cool-onclickoutside'
import { useFloating, offset, shift, flip } from '@floating-ui/react-dom'

interface ToolbarColorPickerProps {
  editor: LexicalEditor
  textColor: string
  textBackgroundColor: string
}

export function ToolbarColorPicker({
  editor,
  textColor,
  textBackgroundColor,
}: ToolbarColorPickerProps) {
  const [showPicker, setShowPicker] = useState(false)
  const [color, setColor] = useState(textColor)
  const [backgroundColor, setBackgroundColor] = useState(textBackgroundColor)

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [offset(8), flip(), shift()],
  })

  const handleColorChange = useCallback(
    (newColor: string) => {
      // check if linear gradient
      const isGradient = newColor.includes('gradient')

      if (isGradient) {
        setBackgroundColor(newColor)
        setColor('')
      } else {
        setColor(newColor)
        setBackgroundColor('')
      }

      editor.update(() => {
        const selection = $getSelection()
        if ($INTERNAL_isPointSelection(selection)) {
          // Reset the style to remove the gradient effect
          $patchStyleText(selection, {
            color: null,
            '--lexical-linear-gradient': null,
            'background-clip': null,
          })

          $patchStyleText(
            selection,
            isGradient
              ? {
                  '--lexical-linear-gradient': newColor,
                  color: 'transparent',
                  'background-clip': 'text',
                }
              : {
                  color: newColor,
                },
          )
        }
      })
    },
    [editor],
  )

  useOnClickOutside(
    () => {
      if (showPicker) {
        setShowPicker(false)
      }
    },
    {
      refs: [refs.floating],
      ignoreClass: ['wysiwyg-toolbar-toggle-item--color-picker'],
    },
  )

  return (
    <>
      <Toolbar.ToggleGroup
        type="multiple"
        aria-label="Text color"
        className="wysiwyg-toolbar-toggle-group"
      >
        <Toolbar.ToggleItem
          className="wysiwyg-toolbar-toggle-item wysiwyg-toolbar-toggle-item--color-picker"
          value="color-picker"
          aria-label="Text Color"
          ref={refs.setReference}
          onClick={() => setShowPicker(!showPicker)}
        >
          <div
            className="wysiwyg-toolbar-color-preview"
            style={{
              width: '16px',
              height: '16px',
              borderRadius: '4px',
              background: backgroundColor || color,
              border: '1px solid #E7E8E5',
            }}
          />
        </Toolbar.ToggleItem>
      </Toolbar.ToggleGroup>
      {showPicker && (
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            border: '1px solid #E7E8E5',
            borderRadius: '16px',
            backgroundColor: '#fff',
            padding: '8px',
            zIndex: 9999,
            fontSize: '0.75rem',
          }}
        >
          <ColorPicker
            value={backgroundColor || color}
            onChange={handleColorChange}
            width={240}
            height={240}
            hideAdvancedSliders
            hideColorGuide
            hideInputType
            disableDarkMode
          />
        </div>
      )}
    </>
  )
}
