import React from 'react'
import {
  GenericSettings,
  RHFBackgroundSettings,
  RHFBorderSettings,
  RHFNumericInput,
  RHFRadiusInput,
  RHFSelect,
  RHFShadowSettings,
  RHFSpacingSettings,
} from '@sceneio/ui-forms'
import { AVAILABLE_CONTAINER_NAMES } from '../Container'
import { ALIGNMENT_FLEX_OPTIONS } from '@sceneio/ui-forms/lib/components/atoms/Alignment/alignmentOptions'
import { Icon } from '@sceneio/ui-icons'
import type { Icons } from '@sceneio/ui-icons'
import { GenericSettingsPropType } from '@sceneio/ui-forms/lib/components/molecules/GenericSettings/GenericSettings'

export const containerComponentFormRegisterValuesFactory = (
  containerName: (typeof AVAILABLE_CONTAINER_NAMES)[number],
) => {
  return {
    background: {
      responsiveConfig: {
        default: {
          color: '#fff',
          overlay: 'rgba(0,0,0,0.15)',
          image: {
            src: 'https://assets.scene.io/global/placeholders/media-1200x900.png',
          },
          filter: {
            blur: '10px',
          },
        },
      },
    },
    padding: {
      data: {
        [`--padding-default-bottom`]: 0,
        [`--padding-default-top`]: 0,
        [`--padding-default-left`]: 0,
        [`--padding-default-right`]: 0,
      },
    },
    margin: {
      data: {
        [`--margin-default-bottom`]: 0,
        [`--margin-default-top`]: 0,
        [`--margin-default-left`]: 0,
        [`--margin-default-right`]: 0,
      },
    },
    border: {
      data: {
        default: {
          [`--border-default-color-default`]: '#000',
          [`--border-default-width-default`]: '1px',
          [`--border-default-style-default`]: 'solid',
          [`--border-default-top-style-default`]: 'solid',
          [`--border-default-right-style-default`]: 'solid',
          [`--border-default-bottom-style-default`]: 'solid',
          [`--border-default-left-style-default`]: 'solid',
        },
      },
      meta: {
        dataType: 'border-style',
      },
    },
    boxShadow: {
      data: {
        responsiveConfig: {
          default: {
            default: [
              {
                x: '2px',
                y: '2px',
                blur: '0px',
                spread: '0px',
                color: 'rgba(0, 0, 0, 0.5)',
                inset: false,
              },
            ],
          },
        },
      },
      meta: {
        dataType: 'box-shadow-style',
      },
    },
  }
}

type ContainerComponentFormType = {
  mediaRelatedProps?: {
    onMediaAddClick?: (data: {
      fieldName: string
      sourceType: 'IMAGE' | 'VIDEO'
    }) => void
  }
  name: 'group' | 'componentArray' | 'component'
  additionalFormFields: {
    [key: string]: {
      formFieldRenderer: GenericSettingsPropType['additionalFormFieldRenderer']
    }
  }
}

export function ContainerComponentForm({
  mediaRelatedProps: { onMediaAddClick } = {},
  additionalFormFields,
  name,
}: ContainerComponentFormType) {
  return (
    <div>
      {/* temp solution for Header menu/buttons container which should have additional "Display" molecule */}
      {additionalFormFields?.display?.formFieldRenderer && (
        <GenericSettings
          title="Display"
          formFieldRenderer={() => null}
          additionalFormFieldRenderer={
            additionalFormFields?.display?.formFieldRenderer
          }
        />
      )}
      <GenericSettings
        formFieldRenderer={({ breakpoint }) => ({
          fieldNames: [
            `gap.data.--column-gap-${breakpoint}`,
            `gap.data.--row-gap-${breakpoint}`,
            `horizontalAlignment.data.--horizontal-alignment-${breakpoint}`,
            `verticalAlignment.data.--vertical-alignment-${breakpoint}`,
            `border.data.default.--border-${breakpoint}-radius-top-left-default`,
            `border.data.default.--border-${breakpoint}-radius-top-right-default`,
            `border.data.default.--border-${breakpoint}-radius-bottom-right-default`,
            `border.data.default.--border-${breakpoint}-radius-bottom-left-default`,
          ],
          children: (
            <div className="tw-flex tw-flex-col tw-gap-1">
              <div className="tw-flex tw-gap-1">
                <RHFNumericInput
                  label={
                    <Icon
                      provider="scene"
                      icon="horizontal-gap"
                      size={'1rem'}
                    />
                  }
                  name={`gap.data.--column-gap-${breakpoint}`}
                  units={['px', 'rem']}
                  {...(breakpoint === 'default' && {
                    fallbackValue: '0rem',
                  })}
                />
                <RHFNumericInput
                  label={
                    <Icon provider="scene" icon="vertical-gap" size={'1rem'} />
                  }
                  name={`gap.data.--row-gap-${breakpoint}`}
                  units={['px', 'rem']}
                  {...(breakpoint === 'default' && {
                    fallbackValue: '0rem',
                  })}
                />
              </div>
              <div className="tw-flex tw-gap-1">
                <RHFSelect
                  name={`horizontalAlignment.data.--horizontal-alignment-${breakpoint}`}
                  placeholder={'flex-start'}
                  options={ALIGNMENT_FLEX_OPTIONS['horizontal'].map(
                    (option) => ({
                      value: option.value,
                      label: (
                        <div className="tw-flex tw-gap-1">
                          <Icon
                            provider="phosphor"
                            icon={option.icon as Icons['phosphor']}
                          />
                        </div>
                      ),
                    }),
                  )}
                />
                <RHFSelect
                  name={`verticalAlignment.data.--vertical-alignment-${breakpoint}`}
                  placeholder={'flex-start'}
                  options={ALIGNMENT_FLEX_OPTIONS['vertical'].map((option) => ({
                    value: option.value,
                    label: (
                      <div className="tw-flex tw-gap-1">
                        <Icon
                          provider="phosphor"
                          icon={option.icon as Icons['phosphor']}
                        />
                      </div>
                    ),
                  }))}
                />
              </div>
              <RHFRadiusInput valuesPath="border" breakpoint={breakpoint} />
            </div>
          ),
        })}
        additionalFormFieldRenderer={
          additionalFormFields?.layout?.formFieldRenderer
        }
      />
      <RHFSpacingSettings title="Margin" type={`margin`} basePath="margin" />
      <RHFSpacingSettings title="Padding" type={`padding`} basePath="padding" />
      <RHFBackgroundSettings
        basePath="background"
        title="Background"
        onMediaAddClick={onMediaAddClick}
      />
      <RHFBorderSettings basePath="border" />
      <RHFShadowSettings
        basePath="boxShadow"
        shadowType="box"
        title="Box shadow"
      />
    </div>
  )
}
