import { object, InferType, string, bool } from 'yup'
import { cssLengthUnitSchema } from '../../styles/common/cssLengthUnitSchema'

const responsiveBreakpointConfigSchema = object({
  overlay: string(),
  color: string(),
  image: object({
    src: string().required(),
    alt: string().optional(),
  })
    .noUnknown()
    .optional(),
  filter: object({
    blur: cssLengthUnitSchema,
  })
    .noUnknown()
    .optional(),
})
  .noUnknown()
  .optional()

export const backgroundSchema = object({
  fit: string().oneOf(['cover', 'contain']),
  parallax: bool(),
  responsiveConfig: object({
    default: responsiveBreakpointConfigSchema,
    xs: responsiveBreakpointConfigSchema,
    md: responsiveBreakpointConfigSchema,
    lg: responsiveBreakpointConfigSchema,
  })
    .noUnknown()
    .optional(),
}).noUnknown()

export type Background = InferType<typeof backgroundSchema>
