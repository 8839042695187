import { object, InferType, mixed, string, boolean } from 'yup'

export const componentEditorSchema = object({
  selectedComponentId: string(),
  selectedBlockId: string(),
  persistentComponentSelection: object().optional(),
  resolveClassName:
    mixed<(data: { blockId?: string; componentId?: string }) => any>(),
  onClick: mixed<(data: { componentId?: string }) => void>(),
  blockId: string(),
  id: string(),
  itemsId: string(),
  wysiwyg: object({
    type: mixed<'TEXT' | 'ITEMS' | 'HEADING' | 'RAW'>().oneOf([
      'TEXT',
      'ITEMS',
      'HEADING',
      'RAW',
    ]),
    itemAdd: mixed<() => { id: string; value: any }>(),
    itemRemove: mixed<() => { id: string; value: any }>(),
    disableItemAdd: boolean(),
    disableItemRemove: boolean(),
  }).optional(),
})
  .noUnknown()
  .optional()

export type ComponentEditor = InferType<typeof componentEditorSchema>
