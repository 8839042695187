import { useEffect, useState } from 'react'
import { useFormContext } from '../../form/context/FormContext'
import {
  BackgroundType,
  RHFBackgroundSettingsPropType,
} from './BackgroundSettings'
import { stringify } from '@sceneio/tools'
import { BreakpointType } from '../../atoms/BreakpointDropdown/BreakpointDropdown'
import clsx from 'clsx'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { HeaderDropdown } from '../../atoms/HeaderDropdown/HeaderDropdown'
import { resolveActiveBackgrounds } from './helpers'
import { BreakpointBackgroundFields } from './components/BreakpointBackgroundFields'
import { InheritedSettingsWrapper } from '../../atoms/InheritedSettingsWrapper/InheritedSettingsWrapper'
import { Icon } from '@sceneio/ui-icons'

export const ResponsiveBackgroundSettings = ({
  basePath,
  className,
  title = 'Background',
  options = ['color', 'image', 'overlay', 'blur'],
  onMediaAddClick,
}: RHFBackgroundSettingsPropType) => {
  const {
    values,
    unregister,
    register,
    registerValues,
    forceBreakpoint,
    onEditDefaultFrameClick,
  } = useFormContext({
    name: basePath,
  })
  const valuesPath = basePath || ''
  const [isBreakpointActive, setIsBreakpointActive] = useState(false)

  const hasBreakpointValues = Object.keys(values).some(
    (val) =>
      val.startsWith(`${valuesPath}`) &&
      val.includes(`responsiveConfig.${forceBreakpoint}`),
  )
  const hasDefaultValues = Object.keys(values).some((val) =>
    val.includes(`responsiveConfig.default`),
  )

  useEffect(() => {
    if (hasBreakpointValues) {
      setIsBreakpointActive(true)
    } else {
      setIsBreakpointActive(false)
    }
  }, [stringify(hasBreakpointValues)])

  const onBackgroundTypeSelect = ({ type }: { type: BackgroundType }) => {
    if (type === 'color' || type === 'overlay') {
      register({
        [`${valuesPath}.responsiveConfig.${forceBreakpoint}.${type}`]:
          registerValues[`${valuesPath}.responsiveConfig.default.${type}`],
        ...(!hasDefaultValues && {
          [`${valuesPath}.responsiveConfig.default.color`]:
            registerValues[`${valuesPath}.responsiveConfig.default.color`],
        }),
      })
    }
    if (type === 'image') {
      register({
        [`${valuesPath}.responsiveConfig.${forceBreakpoint}.image.src`]:
          registerValues[`${valuesPath}.responsiveConfig.default.image.src`],
        [`${valuesPath}.responsiveConfig.${forceBreakpoint}.image.alt`]:
          registerValues[`${valuesPath}.responsiveConfig.default.image.alt`],
        ...(!hasDefaultValues && {
          [`${valuesPath}.responsiveConfig.default.color`]:
            registerValues[`${valuesPath}.responsiveConfig.default.color`],
        }),
      })
    }
    if (type === 'blur') {
      register({
        [`${valuesPath}.responsiveConfig.${forceBreakpoint}.filter.blur`]:
          registerValues[`${valuesPath}.responsiveConfig.default.filter.blur`],
      })
    }
  }

  return (
    <SegmentWrapper className={clsx('sc-bp-background-settings', className)}>
      <HeaderSegment
        heading={title}
        customChildren={
          <>
            {options.length === 1 ? (
              <button
                type="button"
                className={clsx('tw-p-1', { 'tw-hidden': hasBreakpointValues })}
                onClick={() => {
                  onBackgroundTypeSelect({
                    type: options[0] as BackgroundType,
                  })
                }}
              >
                <Icon
                  provider="phosphor"
                  icon={
                    hasDefaultValues && !hasBreakpointValues
                      ? 'PencilSimple'
                      : 'Plus'
                  }
                />
              </button>
            ) : (
              <HeaderDropdown
                icon={
                  hasDefaultValues && !hasBreakpointValues
                    ? 'PencilSimple'
                    : 'Plus'
                }
                options={options}
                disabledOptions={resolveActiveBackgrounds({
                  values,
                  valuesPath,
                  breakpoint: forceBreakpoint as BreakpointType,
                })}
                onSelect={(type) => {
                  onBackgroundTypeSelect({
                    type: type as BackgroundType,
                  })
                }}
              />
            )}
            {hasBreakpointValues && (
              <button
                type="button"
                className="tw-p-1"
                onClick={() => {
                  unregister([
                    `${valuesPath}.responsiveConfig.${forceBreakpoint}`,
                  ])
                }}
              >
                Reset
              </button>
            )}
          </>
        }
      />
      {isBreakpointActive && (
        <BreakpointBackgroundFields
          breakpoint={forceBreakpoint!}
          valuesPath={valuesPath}
          onMediaAddClick={onMediaAddClick}
        />
      )}
      {(hasDefaultValues || isBreakpointActive) && (
        <InheritedSettingsWrapper
          onEditClick={() =>
            onEditDefaultFrameClick({ focusedMolecule: 'background' })
          }
        >
          <BreakpointBackgroundFields
            breakpoint={'default'}
            valuesPath={valuesPath}
            onMediaAddClick={onMediaAddClick}
          />
        </InheritedSettingsWrapper>
      )}
    </SegmentWrapper>
  )
}
