import { textToLexical } from '@sceneio/wysiwyg-utils'
import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../Text/constants/globalTextCssVariables'
import { CountdownItemComponentSchema } from '@sceneio/schemas/lib/components/countdownComponentSchema'

const countdownItemDefaultConfig = (
  label: string,
): CountdownItemComponentSchema => ({
  meta: {
    dataType: 'container-component',
  },
  flow: {
    data: {
      '--flow-default': 'column',
    },
  },
  horizontalAlignment: {
    data: {
      '--horizontal-alignment-default': 'center',
    },
  },
  show: true,
  components: {
    text: {
      meta: {
        dataType: 'text-component',
      },
      ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
      as: 'h1',
    },
    label: {
      meta: {
        dataType: 'text-component',
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
        data: {
          default: {
            '--font-default-size-default': '0.9rem',
          },
        },
      },
      children: textToLexical(label),
      as: 'p',
    },
  },
})

export const countdownComponentMeta: ComponentMeta<'countdown'> = {
  defaultConfig: {
    meta: {
      dataType: 'countdown-component',
    },
    show: true,
    horizontalAlignment: {
      data: {
        '--horizontal-alignment-default': 'center',
      },
    },
    width: {
      data: {
        '--width-default': '100%',
      },
    },
    padding: {
      data: {
        '--padding-default-top': '1rem',
        '--padding-default-bottom': '1rem',
      },
    },
    components: {
      days: countdownItemDefaultConfig('Days'),
      hours: countdownItemDefaultConfig('Hours'),
      minutes: countdownItemDefaultConfig('Minutes'),
      seconds: countdownItemDefaultConfig('Seconds'),
      dots: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
        as: 'h1',
      },
    },
  },
}

export const countdownDefaults = componentDefaultConfigFactory<'countdown'>({
  componentMeta: countdownComponentMeta,
})
