import React, { useContext } from 'react'
import clsx from 'clsx'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { textDefaults } from './textMeta'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getFontSmoothingCssVariables } from '../../tools/cssVariables/getFontSmoothingCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { TextComponent } from '@sceneio/schemas/lib/types'
import { AppContext } from '@sceneio/components-app-context'
import { renderWysiwygOrHtml } from '@sceneio/wysiwyg-renderer'

const TEXT_DEFAULTS = textDefaults.config

const Text = ({
  children,
  className,
  as: Component = TEXT_DEFAULTS.as,
  horizontalAlignment,
  color,
  dataAttributes = TEXT_DEFAULTS.dataAttributes,
  style,
  font,
  textShadow,
  boxShadow,
  border,
  padding,
  linkStyle,
  margin,
  backgroundColor,
  cid,
  isAnimated = TEXT_DEFAULTS.isAnimated,
  ariaAttributes = TEXT_DEFAULTS.ariaAttributes,
  role,
  id,
  editor,
  shouldHyphenate = TEXT_DEFAULTS.shouldHyphenate,
  show = TEXT_DEFAULTS.show,
}: TextComponent) => {
  const app = useContext(AppContext)
  const isEditor = app !== 'WEBSITE'

  if (!show || typeof children === 'undefined' || typeof children === null) {
    return null
  }

  const textClass = clsx(
    'sc-text',
    {
      [`sc-text--enable-hyphens`]: shouldHyphenate,
    },
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    className,
  )

  let Tag = 'div'

  if (!isEditor && editor?.wysiwyg?.type === 'ITEMS') {
    Tag = 'span'
  }

  return (
    <>
      <Tag
        {...dataAttributes}
        {...ariaAttributes}
        role={role}
        id={id}
        className={textClass}
        data-component-id={editor?.id}
        style={{
          ...style,
          ...getFontSmoothingCssVariables({ font, prefix: 'text' }),
          ...getRootLevelCssVariables({
            cssVariables: {
              font,
              backgroundColor,
              padding,
              margin,
              horizontalAlignment,
              linkStyle,
              color,
              border,
            },
            prefix: 'text',
          }),
          ...getShadowCssVariables({
            shadow: textShadow,
            shadowType: 'text',
            prefix: 'text',
          }),
          ...getShadowCssVariables({
            shadow: boxShadow,
            shadowType: 'box',
            prefix: 'text',
          }),
        }}
        data-plugin-animation={isAnimated ? true : null}
        {...(editor
          ? renderWysiwygOrHtml({
              isEditor,
              serialisedEditorState: children,
              type: editor?.wysiwyg?.type,
              componentId: editor.id || '',
              itemAdd: editor?.wysiwyg?.itemAdd,
              itemRemove: editor?.wysiwyg?.itemRemove,
              disableItemAdd: editor?.wysiwyg?.disableItemAdd,
              disableItemRemove: editor?.wysiwyg?.disableItemRemove,
              as: Component,
            })
          : { children })}
      />
    </>
  )
}

export const plugins: PluginsType = {
  js: ['animation'],
  css: [],
}

export default Text
