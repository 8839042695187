import React from 'react'
import clsx from 'clsx'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { CountdownItem } from './components/CountdownItem'
import { CountdownComponent } from '@sceneio/schemas/lib/types'

function Countdown({
  selectedDate,
  components: { days, hours, minutes, seconds, dots },
  editor,
  cid,
  horizontalAlignment,
  verticalAlignment,
  gap,
  width,
  padding,
  margin,
  border,
  boxShadow,
  background,
  style,
  preview,
  className,
  show,
}: CountdownComponent) {
  if (!show) {
    return null
  }

  return (
    <div
      className={clsx(
        'sc-countdown',
        { 'sc-countdown--js-loading': !preview },
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
        className,
      )}
      data-component-id={editor?.id}
      data-plugin-countdown={JSON.stringify({
        selectedDate,
        isEditorView: preview,
      })}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            horizontalAlignment,
            verticalAlignment,
            gap,
            width,
            padding,
            margin,
            border,
          },
          prefix: 'countdown',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'countdown',
        }),
        ...getBackgroundCssVariables({
          background,
          prefix: 'countdown',
        }),
      }}
    >
      <CountdownItem
        editor={editor}
        item={days}
        id="days"
        cid={cid}
        dots={dots}
      />
      <CountdownItem
        editor={editor}
        item={hours}
        id="hours"
        cid={cid}
        dots={dots}
      />
      <CountdownItem
        editor={editor}
        item={minutes}
        id="minutes"
        cid={cid}
        dots={dots}
      />
      <CountdownItem
        editor={editor}
        item={seconds}
        id="seconds"
        cid={cid}
        dots={dots}
      />
    </div>
  )
}

export default Countdown

export const plugins: PluginsType = {
  js: ['countdown'],
  css: [],
}
