import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'
import {
  $getRoot,
  $isElementNode,
  $isTextNode,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  LexicalNode,
} from 'lexical'

export const RESET_PATCH_STYLES_COMMAND: LexicalCommand<undefined> =
  createCommand('RESET_PATCH_STYLES_COMMAND')

export function ResetPatchStylesPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const unregister = editor.registerCommand<undefined>(
      RESET_PATCH_STYLES_COMMAND,
      () => {
        editor.update(() => {
          const root = $getRoot()

          const traverse = (node: LexicalNode) => {
            if ($isTextNode(node)) {
              node.setFormat(0)
              node.setStyle('')
            }
            if ($isElementNode(node)) {
              node.getChildren().forEach(traverse)
            }
          }

          root.getChildren().forEach(traverse)
        })
        return true
      },
      COMMAND_PRIORITY_EDITOR,
    )

    return () => {
      unregister()
    }
  }, [editor])

  return null
}
