import { EditorState } from 'lexical'
import { useContext } from 'react'
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import FloatingTextFormatToolbarPlugin from './plugins/floatingTextFormatToolbar/FloatingTextFormatToolbarPlugin'
import { WysiwygContext } from './WysiwygProvider'
import { OnEditorStateChangePlugin } from './plugins/onEditorStateChange/OnEditorStateChangePlugin'
import { OnChangePlugin } from './plugins/onChange/OnChangePlugin'
import wysiwygConfig from '@sceneio/config-wysiwyg'
import type { CommonEditorType } from './types'
import { debounce } from '@sceneio/tools/lib/debounce'
import { LineBreakOnEnterPlugin } from './plugins/lineBreakOnEnterPlugin/LineBreakOnEnterPlugin'
import { FixPlaceholderPlugin } from './plugins/fixPlaceholderPlugin/FixPlaceholderPlugin'
import { ResetPatchStylesPlugin } from './plugins/resetPatchStyles/ResetPatchStylesPlugin'

export type WysiwygTextEditor = CommonEditorType

export function WysiwygHeadingEditor({
  componentId,
  serialisedEditorState,
  floatingWhitelist = ['tag', 'history', 'format', 'ai', 'font', 'color'],
}: WysiwygTextEditor) {
  const { onSubmit, anchorRef, resolvePathFromId, disabled, config } =
    useContext(WysiwygContext)

  const { enableAI } = config || {}

  const initialConfig: InitialConfigType = {
    ...wysiwygConfig,
    editable: !disabled,
    namespace: 'HeadingEditor',
    editorState: serialisedEditorState,
    onError: (error: Error) => {
      console.log(error)
    },
  }

  function handleChange(editorState: EditorState) {
    const editorStateJSON = editorState.toJSON()
    const serializedEditorState = JSON.stringify(editorStateJSON)
    if (resolvePathFromId) {
      onSubmit({
        name: resolvePathFromId(componentId),
        data: serializedEditorState,
      })
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="wysiwyg-container notranslate" data-no-drag>
        <FixPlaceholderPlugin>
          <RichTextPlugin
            contentEditable={
              <div className="wysiwyg">
                <ContentEditable className="wysiwyg-input" />
              </div>
            }
            placeholder={
              <div className="wysiwyg-placeholder">Start Typing...</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </FixPlaceholderPlugin>
        <HistoryPlugin />
        <OnChangePlugin onChange={debounce(handleChange, 500)} />
        <LineBreakOnEnterPlugin />
        <ResetPatchStylesPlugin />
        <OnEditorStateChangePlugin
          serialisedEditorState={serialisedEditorState || ''}
        />
        {!disabled &&
          anchorRef.current &&
          Boolean(floatingWhitelist) &&
          Boolean(floatingWhitelist?.length) && (
            <FloatingTextFormatToolbarPlugin
              anchorElem={anchorRef.current}
              floatingWhitelist={floatingWhitelist}
              config={{ enableAI }}
            />
          )}
      </div>
    </LexicalComposer>
  )
}
