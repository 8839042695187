import React from 'react'
import clsx from 'clsx'
import { Container } from '../../../../components/Container/Container'
import Image from '../../../../components/Image/Image'
import Text from '../../../../components/Text/Text'
import resolveComponentId from '../../../../tools/helpers/resolveComponentId'
import type { TestimonialVariant1Card } from '@sceneio/schemas/lib/types'

export function TestimonialCard({
  index,
  config,
  blockId,
  editor,
  isAnimated = true,
}: {
  config: TestimonialVariant1Card
  index: number
  blockId?: string
  editor: any
  isAnimated?: boolean
}) {
  const {
    components: { logo, quote, avatar, name, company },
    ...restConfig
  } = config || {}

  return (
    <Container
      {...restConfig}
      className={clsx('sc-testimonial-variant-1__card')}
      name="group"
      editor={{
        ...editor?.component,
        blockId,
        id: `testimonials.items[${index}]`,
      }}
      flow={{ data: { '--flow-default': 'column' } }}
    >
      <Image
        {...logo}
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `testimonials.items[${index}].logo`,
        }}
        isAnimated={isAnimated}
      />
      <Text
        {...quote}
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `testimonials.items[${index}].quote`,
        }}
        cid={resolveComponentId(`${blockId}`, 'quote')}
        isAnimated={isAnimated}
      />

      <Image
        {...avatar}
        transformationOptions={{ cover: '1:1' }}
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `testimonials.items[${index}].avatar`,
        }}
        isAnimated={isAnimated}
      />
      <Text
        {...name}
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `testimonials.items[${index}].name`,
        }}
        cid={resolveComponentId(`${blockId}`, 'name')}
        isAnimated={isAnimated}
      />
      <Text
        {...company}
        editor={{
          ...editor?.component,
          blockId: blockId,
          id: `testimonials.items[${index}].company`,
        }}
        cid={resolveComponentId(`${blockId}`, 'company')}
        isAnimated={isAnimated}
      />
    </Container>
  )
}
