import {
  BlockComponent,
  GalleryCarouselBlock,
} from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'

import { galleryItemDefaults } from '../../../components/GalleryItem/galleryItemMeta'
import { sliderVariant2Defaults } from '../../../components/Slider/SliderVariant2/sliderVariant2Meta'
import { blockContainerDefaults } from '../../../components/BlockContainer/blockContainerMeta'

const SLIDER_DEFAULTS = sliderVariant2Defaults.config
const GALLERY_ITEM_DEFAULTS = galleryItemDefaults.config
const BLOCK_CONTAINER_DEFAULTS = blockContainerDefaults.config

export const galleryItemSampleFactory = () => ({
  ...GALLERY_ITEM_DEFAULTS,
  id: randomString(8),
})

export const galleryCarouselComponentMeta: BlockComponentMeta<GalleryCarouselBlock> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
      } as BlockComponent,
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            rowGap: '1.5rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: BLOCK_CONTAINER_DEFAULTS.padding,
      },
      type: 'blockContainer',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h2'],
        children: textToLexical('Image Gallery', 'h2'),
        as: 'h2',
        padding: {
          data: {
            '--padding-default-left': '20%',
            '--padding-default-right': '20%',
            '--padding-xs-left': '0%',
            '--padding-xs-right': '0%',
            '--padding-md-left': '10%',
            '--padding-md-right': '10%',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        show: true,
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
        ),
        as: 'p',
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-default-left': '20%',
            '--padding-default-right': '20%',
            '--padding-xs-left': '0%',
            '--padding-xs-right': '0%',
            '--padding-md-left': '10%',
            '--padding-md-right': '10%',
          },
        },
        show: true,
      },
      type: 'text',
    },
    slider: {
      path: 'components.slider',
      name: 'Slider',
      type: 'sliderVariant2',
      components: {
        slides: {
          type: 'container',
          name: 'Slides',
          path: 'components.slides',
          configEditor: {
            disable: true,
          },
          disableToggleShow: true,
          persistentSelectionId: 'slider',
          components: {
            items: {
              name: 'Gallery Item',
              isArrayComponent: true,
              path: 'items',
              type: 'galleryItem',
              disableToggleShow: true,
              sampleFactory: galleryItemSampleFactory,
              components: {
                image: {
                  name: 'Image',
                  path: 'components.image',
                  type: 'galleryImage',
                },
                heading: {
                  type: 'text',
                  name: 'Heading',
                  path: 'components.heading',
                  inlineEditors: [
                    {
                      editorType: 'inlineText',
                      editorPath: 'components.heading',
                      editorOptions: {
                        isNestedArrayItem: true,
                      },
                    },
                  ],
                },
                description: {
                  type: 'text',
                  name: 'Description',
                  path: 'components.description',
                  inlineEditors: [
                    {
                      editorType: 'inlineText',
                      editorPath: 'components.description',
                      editorOptions: {
                        isNestedArrayItem: true,
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        controlsContainer: {
          type: 'container',
          name: 'Controls container',
          path: 'components.controlsContainer',
          components: {
            navigation: {
              type: 'container',
              name: 'Navigation',
              path: 'components.navigation',
              components: {
                buttonPrev: {
                  path: 'components.buttonPrev',
                  name: 'Button prev',
                  type: 'button',
                },
                buttonNext: {
                  path: 'components.buttonNext',
                  name: 'Button next',
                  type: 'button',
                },
              },
              configEditor: {
                formProps: {
                  registerValues:
                    containerComponentFormRegisterValuesFactory('group'),
                },
              },
            },
            pagination: {
              type: 'sliderPagination',
              name: 'Pagination',
              path: 'components.pagination',
            },
          },
          configEditor: {
            formProps: {
              registerValues:
                containerComponentFormRegisterValuesFactory('component'),
            },
          },
        },
      },
      defaultConfig: {
        ...SLIDER_DEFAULTS,
        itemsPerView: {
          responsiveConfig: {
            default: 3,
            md: 2,
            xs: 1,
          },
        },
        gap: {
          data: {
            '--column-gap-default': '24px',
          },
        },
        margin: {
          data: {
            '--margin-default-top': '2.5rem',
          },
        },
        effect: 'slide',
        components: {
          ...SLIDER_DEFAULTS.components,
          slides: {
            ...SLIDER_DEFAULTS.components.slides,
            items: [
              galleryItemSampleFactory(),
              galleryItemSampleFactory(),
              galleryItemSampleFactory(),
              galleryItemSampleFactory(),
              galleryItemSampleFactory(),
              galleryItemSampleFactory(),
            ],
          },
        },
      },
      configEditor: {
        formProps: {
          excludedSettings: ['effect'],
        },
      },
    },
  }

export const galleryCarouselBlockMeta: BlockMeta<GalleryCarouselBlock> = {}

export const galleryCarouselDefaultConfig =
  blockDefaultConfigFactory<GalleryCarouselBlock>({
    componentMeta: galleryCarouselComponentMeta,
    blockMeta: galleryCarouselBlockMeta,
    version: 1,
  })
