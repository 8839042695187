import { ComboboxItem, SelectItem } from '@ariakit/react'
import { Icon } from '@sceneio/ui-icons'
import { SearchableSelectPropType } from './SearchableFontSelect'

export const SelectOption = ({
  value,
  label,
  uploaded,
}: SearchableSelectPropType['fonts'][0]) => (
  <SelectItem
    value={value.toString()}
    className="tw-select-none tw-flex tw-justify-between tw-items-center tw-p-2 tw-mx-1 hover:tw-outline-none focus-visible:tw-outline-none data-[active-item]:tw-bg-secondary aria-[selected=true]:tw-bg-secondary tw-rounded-lg data-[disabled]:tw-text-label-color tw-group"
    render={
      <ComboboxItem>
        <div className="tw-w-full">
          {label}
          {uploaded && (
            <span className="tw-py-1 tw-px-1.5 tw-rounded tw-bg-gray-90 tw-text-gray-50 tw-ml-1 group-data-[active-item]:tw-bg-white group-aria-[selected=true]:tw-bg-white">
              Uploaded
            </span>
          )}
        </div>

        <div className="group-aria-[selected=true]:tw-flex tw-hidden">
          <Icon provider="phosphor" icon="Check" className="tw-ml-1" />
        </div>
      </ComboboxItem>
    }
  ></SelectItem>
)
