import React from 'react'
import { WysiwygTextComponentEditor } from '@sceneio/wysiwyg-editor'
import { lexicalToHtml } from '@sceneio/wysiwyg-utils'
import { LegacyDataConvertor } from './components/LegacyDataConvertor'
import { isJsonString } from '@sceneio/wysiwyg-utils'

type WysiwygOrHtml = WysiwygTextComponentEditor & {
  isEditor: boolean
  serialisedEditorState: string | Record<string, any>
  as?: string
}

export function renderWysiwygOrHtml({
  isEditor,
  serialisedEditorState,
  type,
  componentId,
  itemAdd,
  itemRemove,
  disableItemAdd,
  disableItemRemove,
  as,
}: WysiwygOrHtml) {
  if (type !== 'RAW') {
    if (isEditor) {
      return {
        children: (
          // LegacyDataConvertor converts legacy data
          // TODO remove once fully converted
          <LegacyDataConvertor
            serialisedEditorState={serialisedEditorState}
            as={as}
          >
            {(convertedSerialisedData) => (
              <WysiwygTextComponentEditor
                componentId={componentId}
                type={type}
                serialisedEditorState={convertedSerialisedData}
                itemAdd={itemAdd}
                itemRemove={itemRemove}
                disableItemAdd={disableItemAdd}
                disableItemRemove={disableItemRemove}
              />
            )}
          </LegacyDataConvertor>
        ),
      }
    }

    if (isJsonString(serialisedEditorState)) {
      return {
        dangerouslySetInnerHTML: {
          __html: lexicalToHtml(serialisedEditorState),
        },
      }
    }
  }

  // React children
  if (typeof serialisedEditorState === 'object') {
    return { children: serialisedEditorState }
  }

  return {
    dangerouslySetInnerHTML: {
      __html: as
        ? `<${as} class="sc-text__content">${serialisedEditorState}</${as}>`
        : serialisedEditorState,
    },
  }
}
