import { GridComponent } from '@sceneio/schemas/lib/types'

export const getActiveGridItemInEditor = ({
  editor,
  itemsLength,
}: {
  editor: GridComponent['editor']
  itemsLength: number
}) => {
  if (!editor || !editor?.id || !editor?.blockId || !editor?.selectedBlockId) {
    return 0
  }

  if (editor.selectedBlockId !== editor.blockId) {
    return 0
  }

  if (!editor.selectedComponentId?.includes(editor.id)) {
    return 0
  }

  const selectedId = editor.id

  if (!selectedId) {
    return 0
  }
  const escapedSelectedId = selectedId
    .replace(/\[/g, '\\[')
    .replace(/\]/g, '\\]')
  const gridItemsPattern = new RegExp(
    `^(${escapedSelectedId})\\.items\\[(\\d+)\\](?:\\.|$)`,
  )
  const selectedItemIndex =
    editor?.selectedComponentId?.match(gridItemsPattern)?.[2]

  if (
    !selectedItemIndex ||
    (selectedItemIndex && Number(selectedItemIndex) >= itemsLength)
  ) {
    return 0
  }
  return Number(selectedItemIndex)
}
