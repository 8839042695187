import {
  $getSelection,
  $isRangeSelection,
  BaseSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import { getSelectedNode } from '../../utils/getSelectedNode'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import {
  $createLinkNode,
  $isAutoLinkNode,
  $isLinkNode,
  TOGGLE_LINK_COMMAND,
} from '@lexical/link'
import * as React from 'react'
import { computePosition, flip, offset } from '@floating-ui/dom'
import * as Toolbar from '@radix-ui/react-toolbar'
import * as Switch from '@radix-ui/react-switch'
import { Icon } from '@sceneio/ui-icons'
import clsx from 'clsx'
import { sanitizeUrl, validateUrl } from './helpers'
import useOnClickOutside from 'react-cool-onclickoutside'
import { debounce } from '@sceneio/tools'

export function FloatingLinkEditor({
  editor,
  isLink,
  setIsLink,
  anchorElem,
}: {
  editor: LexicalEditor
  isLink: boolean
  setIsLink: Dispatch<boolean>
  anchorElem: HTMLElement
}) {
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null)
  const [isInvalidUrl, setIsInvalidUrl] = useState(false)
  const lastValidRangeRef = useRef<Range | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [linkUrl, setLinkUrl] = useState('')
  const [editedLinkUrl, setEditedLinkUrl] = useState('https://')
  const [editedLinkTarget, setEditedLinkTarget] = useState('_self')

  const updateFloatingLink = useCallback(() => {
    const popupCharStylesEditorElem = popupCharStylesEditorRef.current

    if (popupCharStylesEditorElem === null) {
      return
    }

    const nativeSelection = window.getSelection()
    const rootElement = editor.getRootElement()
    const editorContainer = rootElement?.closest('.viewport') as HTMLElement
    const infiniteViewer = editorContainer?.closest(
      '.infinite-viewer',
    ) as HTMLElement

    if (!editorContainer || !infiniteViewer) {
      return
    }

    let range: Range | null = lastValidRangeRef.current

    if (
      nativeSelection &&
      !nativeSelection.isCollapsed &&
      rootElement?.contains(nativeSelection.anchorNode)
    ) {
      range = nativeSelection.getRangeAt(0)
      lastValidRangeRef.current = range
    }

    editor.getEditorState().read(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection)
        const linkParent = $findMatchingParent(node, $isLinkNode)

        if (linkParent) {
          setLinkUrl(linkParent.getURL())
          const linkParentTarget = linkParent.getTarget()
          if (linkParentTarget) {
            setEditedLinkTarget(linkParentTarget)
          }
        } else if ($isLinkNode(node)) {
          setLinkUrl(node.getURL())
        } else {
          setLinkUrl('')
        }
        if (isLinkEditMode) {
          setEditedLinkUrl(linkUrl)
        }
      }
    })

    if (!range) {
      popupCharStylesEditorElem.style.opacity = '0'
      setTimeout(() => {
        popupCharStylesEditorElem.style.transform =
          'translate(-10000px, -10000px)'
      }, 500)
      return
    }

    editor.getEditorState().read(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection)
        const linkParent = $findMatchingParent(node, $isLinkNode)
        const linkNode = linkParent || ($isLinkNode(node) ? node : null)

        if (linkNode) {
          // Get the DOM node for the link
          const element = editor.getElementByKey(linkNode.getKey())
          if (element) {
            const domRect = element.getBoundingClientRect()

            const virtualElement = {
              getBoundingClientRect: () => domRect,
            }

            computePosition(virtualElement, popupCharStylesEditorElem, {
              placement: 'bottom',
              middleware: [offset(10), flip()],
            }).then(({ x, y }) => {
              popupCharStylesEditorElem.style.opacity = '1'
              // Position relative to the infinite viewer and apply inverse scale
              popupCharStylesEditorElem.style.transform = `translate(${x}px, ${y}px)`
            })
          }
        }
      }
    })
  }, [editor, isLinkEditMode, linkUrl])

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      const rootElement = editor.getRootElement()
      const canvasElem = rootElement?.closest('.viewport') as HTMLElement
      const infiniteViewer = canvasElem?.closest(
        '.infinite-viewer',
      ) as HTMLElement

      const debouncedUpdate = debounce(updateFloatingLink, 4)

      if (infiniteViewer) {
        infiniteViewer.addEventListener('scroll', debouncedUpdate)
        infiniteViewer.addEventListener('wheel', debouncedUpdate, {
          passive: true,
        })
        infiniteViewer.addEventListener('pinch', debouncedUpdate)
        infiniteViewer.addEventListener('drag', debouncedUpdate)
      }

      return () => {
        if (infiniteViewer) {
          infiniteViewer.removeEventListener('scroll', debouncedUpdate)
          infiniteViewer.removeEventListener('wheel', debouncedUpdate)
          infiniteViewer.removeEventListener('pinch', debouncedUpdate)
          infiniteViewer.removeEventListener('drag', debouncedUpdate)
        }
      }
    }
    return
  }, [popupCharStylesEditorRef, updateFloatingLink, editor])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateFloatingLink()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateFloatingLink()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateFloatingLink()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false)
            return true
          }
          return false
        },
        COMMAND_PRIORITY_HIGH,
      ),
    )
  }, [editor, updateFloatingLink, isLink])

  useEffect(() => {
    if (isLinkEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isLinkEditMode, isLink])

  useOnClickOutside(
    () => {
      setIsLink(false)
    },
    { refs: [popupCharStylesEditorRef] },
  )

  const monitorInputInteraction = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleLinkSubmission()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      setIsLinkEditMode(false)
    }
  }

  const handleLinkSubmission = () => {
    const isValid = validateUrl(editedLinkUrl)
    if (!isValid) {
      setIsInvalidUrl(true)
      return
    }
    if (linkUrl !== '') {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl))
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const parent = getSelectedNode(selection).getParent()
          if ($isAutoLinkNode(parent)) {
            const linkNode = $createLinkNode(parent.getURL(), {
              rel: parent.__rel,
              target: parent.__target,
              title: parent.__title,
            })
            parent.replace(linkNode, true)
          } else if ($isLinkNode(parent)) {
            const linkNode = $createLinkNode(parent.getURL(), {
              rel: parent.__rel,
              target: parent.__target,
              title: parent.__title,
            })
            parent.replace(linkNode, true)
          }
        }
      })
      setEditedLinkUrl('https://')
      setIsLinkEditMode(false)
    }
  }

  const handleNewTabChange = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const parent = getSelectedNode(selection).getParent()
        if ($isLinkNode(parent)) {
          const linkNode = $createLinkNode(editedLinkUrl, {
            target: editedLinkTarget === '_blank' ? '_self' : '_blank',
          })
          parent.replace(linkNode, true)
        }
      }
    })
  }

  if (!isLink) {
    return null
  }

  return (
    <Toolbar.Root
      ref={popupCharStylesEditorRef}
      className="wysiwyg-link-toolbar-root"
      aria-label="Formatting options"
    >
      {isLinkEditMode ? (
        <div data-no-drag>
          <div className="tw-flex tw-items-center tw-mb-2">
            <input
              ref={inputRef}
              className={clsx('wysiwyg-link-toolbar-input', {
                'wysiwyg-link-toolbar-input--invalid': isInvalidUrl,
              })}
              value={editedLinkUrl}
              onChange={(event) => {
                if (isInvalidUrl) {
                  setIsInvalidUrl(false)
                }
                setEditedLinkUrl(event.target.value)
              }}
              onKeyDown={(event) => {
                monitorInputInteraction(event)
              }}
            />
            <div className="tw-flex tw-items-center tw-ml-2">
              <Toolbar.Button
                onClick={handleLinkSubmission}
                className="wysiwyg-toolbar-toggle-item"
              >
                <Icon size={16} provider="phosphor" icon="Check" />
              </Toolbar.Button>
              <Toolbar.Button
                onClick={() => {
                  setIsLinkEditMode(false)
                }}
                className="wysiwyg-toolbar-toggle-item"
              >
                <Icon size={16} provider="phosphor" icon="X" />
              </Toolbar.Button>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-p-1">
            <label
              className="tw-mr-3 tw-text-xs"
              htmlFor="wysiwyg-floating-toolbar-link-target"
            >
              Open in new tab
            </label>
            <Switch.Root
              id="wysiwyg-floating-toolbar-link-target"
              tabIndex={1}
              checked={editedLinkTarget === '_blank'}
              onCheckedChange={handleNewTabChange}
              className={clsx('wysiwyg-floating-toolbar-target-switch-root', {
                'wysiwyg-floating-toolbar-target-switch-root--active':
                  editedLinkTarget === '_blank',
              })}
            >
              <Switch.Thumb
                className={clsx(
                  'wysiwyg-floating-toolbar-target-switch-thumb',
                  {
                    'wysiwyg-floating-toolbar-target-switch-thumb--active':
                      editedLinkTarget === '_blank',
                  },
                )}
              />
            </Switch.Root>
          </div>
        </div>
      ) : (
        <div className="tw-flex tw-items-center" data-no-drag>
          <div className="tw-mr-3 tw-flex tw-items-center">
            <a
              href={sanitizeUrl(linkUrl)}
              target="_blank"
              rel="noopener noreferrer"
              className="wysiwyg-link-toolbar-link"
            >
              {linkUrl}
            </a>
          </div>
          <div className="tw-flex tw-items-center">
            <Toolbar.Button
              onClick={() => {
                setEditedLinkUrl(linkUrl)
                setIsLinkEditMode(true)
              }}
              className="wysiwyg-toolbar-toggle-item"
            >
              <Icon size={16} provider="phosphor" icon="NotePencil" />
            </Toolbar.Button>
            <Toolbar.Button
              onClick={() => {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
              }}
              className="wysiwyg-toolbar-toggle-item"
            >
              <Icon size={16} provider="phosphor" icon="Trash" />
            </Toolbar.Button>
          </div>
        </div>
      )}
    </Toolbar.Root>
  )
}
