import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import type {
  HeroCountdownBlock,
  MediaComponent,
} from '@sceneio/schemas/lib/types'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { mergeDeepRight } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import {
  formDefaults,
  formFieldSampleFactory,
} from '../../../components/Form/formMeta'
import { countdownDefaults } from '../../../components/Countdown/countdownMeta'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../SplitHero/form/additionalFormFieldRenderers'

const MEDIA_DEFAULTS = mediaDefaults.config
const FORM_DEFAULTS = formDefaults.config
const COUNTDOWN_DEFAULTS = countdownDefaults.config

export const heroCountdownComponentMeta: BlockComponentMeta<HeroCountdownBlock> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
        minHeight: {
          data: {
            '--min-height-default': '100vh',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: '60%',
            rowGap: '1.5rem',
          },
          md: {
            maxWidth: '75%',
          },
          xs: {
            maxWidth: 'screen',
          },
        },

        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        padding: {
          data: {
            '--padding-default-bottom': '3rem',
            '--padding-default-top': '3rem',
            '--padding-default-left': '2rem',
            '--padding-default-right': '2rem',
          },
        },
      },
      type: 'blockContainer',
    },
    logo: {
      path: 'components.logo',
      name: 'Logo',
      defaultConfig: {
        meta: {
          dataType: 'image-component',
        },
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/logo-ipsum-vertical.svg',
            width: 170,
          },
        },
        show: false,
      },
      type: 'image',
    },
    tagline: {
      path: 'components.tagline',
      name: 'Tagline',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.tagline',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        children: textToLexical('Tagline'),
        as: 'p',
        font: {
          meta: {
            dataType: 'font-style',
          },
          data: {
            default: {
              '--font-default-weight-default': 'bold',
            },
          },
        },
      },
      type: 'text',
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
        children: textToLexical('Medium length hero headline goes here', 'h1'),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        // font: {
        //   meta: {
        //     dataType: 'font-style',
        //   },
        //   data: {
        //     default: {
        //       '--font-default-size-default': '4rem',
        //       '--font-md-size-default': '2.5rem',
        //       '--font-xs-size-default': '2.25rem',
        //       '--font-default-weight-default': 'bold',
        //       '--font-default-line-height-default': 1.2,
        //     },
        //   },
        // },
        as: 'h1',
        show: true,
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
        ),
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        as: 'p',
        show: true,
      },
      type: 'text',
    },
    background: {
      path: 'components.background',
      name: 'Media layer',
      components: {
        credits: {
          path: 'components.credits',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.background.components.credits',
            },
          ],
          disableEditing: true,
          name: 'Credits',
          type: 'text',
        },
      },
      defaultConfig: {
        ...(mergeDeepRight(MEDIA_DEFAULTS, {
          components: {
            ...MEDIA_DEFAULTS.components,
            video: {
              isBackgroundVideo: true,
            },
          },
          show: false,
        }) as MediaComponent),
      },
      type: 'media',
    },
    countdown: {
      path: 'components.countdown',
      name: 'Countdown',
      type: 'countdown',
      components: {
        days: {
          path: 'components.days',
          name: 'Days',
          type: 'container',
          disableToggleShow: true,
          components: {
            text: {
              path: 'components.text',
              name: 'Number',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.text',
                },
              ],
            },
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                },
              ],
            },
          },
          configEditor: {
            formProps: {
              registerValues:
                containerComponentFormRegisterValuesFactory('group'),
            },
            additionalFormFields: {
              layout: {
                formFieldRenderer:
                  buttonContainerLayoutAdditionalFormFieldRenderer,
              },
            },
          },
        },
        hours: {
          path: 'components.hours',
          name: 'Hours',
          type: 'container',
          disableToggleShow: true,
          components: {
            text: {
              path: 'components.text',
              name: 'Number',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.text',
                },
              ],
            },
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                },
              ],
            },
          },
          configEditor: {
            formProps: {
              registerValues:
                containerComponentFormRegisterValuesFactory('group'),
            },
            additionalFormFields: {
              layout: {
                formFieldRenderer:
                  buttonContainerLayoutAdditionalFormFieldRenderer,
              },
            },
          },
        },
        minutes: {
          path: 'components.minutes',
          name: 'Minutes',
          type: 'container',
          disableToggleShow: true,
          components: {
            text: {
              path: 'components.text',
              name: 'Number',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.text',
                },
              ],
            },
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                },
              ],
            },
          },
          configEditor: {
            formProps: {
              registerValues:
                containerComponentFormRegisterValuesFactory('group'),
            },
            additionalFormFields: {
              layout: {
                formFieldRenderer:
                  buttonContainerLayoutAdditionalFormFieldRenderer,
              },
            },
          },
        },
        seconds: {
          path: 'components.seconds',
          name: 'Seconds',
          type: 'container',
          components: {
            text: {
              path: 'components.text',
              name: 'Number',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.text',
                },
              ],
            },
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                },
              ],
            },
          },
          configEditor: {
            formProps: {
              registerValues:
                containerComponentFormRegisterValuesFactory('group'),
            },
            additionalFormFields: {
              layout: {
                formFieldRenderer:
                  buttonContainerLayoutAdditionalFormFieldRenderer,
              },
            },
          },
        },
        dots: {
          path: 'components.dots',
          name: 'Countdown colon',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.text',
            },
          ],
        },
      },
      defaultConfig: {
        ...COUNTDOWN_DEFAULTS,
      },
    },
    form: {
      type: 'form',
      path: 'components.form',
      name: 'Form',
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Email field',
          type: 'formField',
          disableToggleShow: true,
          components: {
            label: {
              path: 'components.label',
              name: 'Label',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.label',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            field: {
              path: 'components.field',
              name: 'Input',
              type: 'input',
            },
            requiredValueError: {
              path: 'components.requiredValueError',
              name: 'Required value',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.requiredValueError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            invalidPatternError: {
              path: 'components.invalidPatternError',
              name: 'Invalid pattern',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.invalidPatternError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            minLengthError: {
              path: 'components.minLengthError',
              name: 'Min length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.minLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
            maxLengthError: {
              path: 'components.maxLengthError',
              name: 'Max length',
              type: 'text',
              inlineEditors: [
                {
                  editorType: 'inlineText',
                  editorPath: 'components.maxLengthError',
                  editorOptions: {
                    isNestedArrayItem: true,
                  },
                },
              ],
            },
          },
        },
        button: {
          path: 'components.button',
          name: 'Submit button',
          type: 'button',
          configEditor: {
            formProps: {
              excludedSettings: ['link'],
            },
          },
        },
        successMessage: {
          path: 'components.successMessage',
          name: 'Success message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.successMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
        errorMessage: {
          path: 'components.errorMessage',
          name: 'Error message',
          type: 'text',
          inlineEditors: [
            {
              editorType: 'inlineText',
              editorPath: 'components.errorMessage',
              editorOptions: {
                isNestedArrayItem: true,
              },
            },
          ],
        },
      },
      defaultConfig: {
        ...FORM_DEFAULTS,
        items: [
          formFieldSampleFactory({
            name: 'email',
            type: 'email',
            showLabel: false,
            placeholder: 'Enter your email',
            label: 'Email',
          }),
        ],
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        padding: {
          data: {
            '--padding-default-top': '1rem',
          },
        },
        components: {
          ...FORM_DEFAULTS.components,
          button: {
            ...FORM_DEFAULTS.components.button,
            children: textToLexical('Join waitlist'),
            font: {
              meta: {
                dataType: 'font-style',
              },
              data: {
                default: {
                  ...FORM_DEFAULTS.components.button.font?.data?.default,
                  '--font-default-weight-default': 600,
                },
              },
            },
          },
          successMessage: {
            ...FORM_DEFAULTS.components.successMessage,
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-default': 'center',
              },
            },
          },
          errorMessage: {
            ...FORM_DEFAULTS.components.errorMessage,
            children: textToLexical(
              'Something went wrong, and we couldn’t submit your email. Please try again.',
            ),
            horizontalAlignment: {
              data: {
                '--horizontal-alignment-default': 'center',
              },
            },
          },
        },
      },
    },
  }

export const heroCountdownBlockMeta: BlockMeta<HeroCountdownBlock> = {
  defaultConfig: {},
  configEditor: {},
}

export const heroCountdownDefaultConfig =
  blockDefaultConfigFactory<HeroCountdownBlock>({
    componentMeta: heroCountdownComponentMeta,
    blockMeta: heroCountdownBlockMeta,
    version: 1,
  })
