import React, { useEffect, useState } from 'react'
import { htmlToLexical } from '@sceneio/wysiwyg-utils'
import { isJsonString } from '@sceneio/wysiwyg-utils'

/**
 * In case of incompatible Wysiwyg data it converts it and passes it to the children
 * This is a temporary component and should be removed when all data has been converted.
 */
export function LegacyDataConvertor({
  serialisedEditorState,
  children,
  as,
}: {
  serialisedEditorState: string
  children: (data: string) => React.ReactElement
  as?: string
}) {
  const [convertedSerialisedEditorState, setConvertedSerialisedEditorState] =
    useState('')

  useEffect(() => {
    // checks if editor state is compatible (e.g. in serialised JSON format)
    if (serialisedEditorState && isJsonString(serialisedEditorState)) {
      // no conversion go with what was supplied
      setConvertedSerialisedEditorState(serialisedEditorState)
    } else {
      let htmlString = serialisedEditorState

      // Wrap the legacy html content in as tag
      if (as && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(as)) {
        htmlString = `<${as}>${htmlString}</${as}>`
      }
      // convert to lexical format
      htmlToLexical(htmlString || '').then((value) => {
        setConvertedSerialisedEditorState(value)
      })
    }
  }, [serialisedEditorState])

  if (!convertedSerialisedEditorState) {
    return null
  }

  return <>{children(convertedSerialisedEditorState)}</>
}
