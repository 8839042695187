import type { UserState, ActionWithPayload } from '../types'
import { USER } from '../constatnts'

const initialState: UserState = {
  id: '',
  name: '',
  email: '',
  localPreferences: {},
  token: null,
  isLoggedIn: false,
  selectedCmsLanguage: 'en',
  selectedProject: null,
  originUser: null,
  createdAt: '',
  hasCompletedSegmentation: undefined,
  myWorkspaceId: '',
}

export default function userReducer(
  state: UserState = initialState,
  { type, payload }: ActionWithPayload<any>,
): UserState {
  // AUTH
  if (type === USER.LOGIN_SUCCESS) {
    const {
      id,
      name,
      email,
      token,
      selectedProject,
      originUser,
      createdAt,
      isFirstLogin,
      hasCompletedSegmentation,
      myWorkspaceId,
    } = payload
    return {
      ...state,
      id,
      name,
      email,
      token,
      selectedProject,
      isLoggedIn: true,
      originUser,
      createdAt,
      isFirstLogin,
      hasCompletedSegmentation,
      myWorkspaceId,
    }
  }

  if (type === USER.LOGOUT) {
    return initialState
  }

  // OTHERS
  if (type === USER.SELECT_LANGUAGE_CMS) {
    return {
      ...state,
      selectedCmsLanguage: payload,
    }
  }

  if (type === 'USER:ADD:INTERCOM:VAL') {
    return {
      ...state,
      localPreferences: {
        ...state.localPreferences,
        intercom: {
          ...state.localPreferences?.intercom,
          ...payload,
        },
      },
    }
  }

  if (type === USER.UPDATE) {
    return {
      ...state,
      name: payload.name || state.name,
      hasCompletedSegmentation:
        payload.hasCompletedSegmentation ?? state.hasCompletedSegmentation,
      createdAt: payload.createdAt || state.createdAt,
      token: payload.token ?? state.token,
      myWorkspaceId: payload.myWorkspaceId ?? state.myWorkspaceId,
    }
  }

  return state
}
