import {
  $getSelection,
  $INTERNAL_isPointSelection,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'
import { mergeRegister } from '@lexical/utils'
import * as Toolbar from '@radix-ui/react-toolbar'
import { Icon } from '@sceneio/ui-icons'
import { $patchStyleText } from '@lexical/selection'
import { blockTypeToBlockName } from './FloatingTextFormatToolbarPlugin'
import { TOGGLE_LINK_COMMAND } from '@lexical/link'
import { ToolbarFontSelect } from './ToolbarFontSelect'
import { ToolbarTagSelect } from './ToolbarTagSelect'
import { ToolbarFontWeightSelect } from './ToolbarFontWeightSelect'
import { ToolbarColorPicker } from './ToolbarColorPicker'
import { debounce } from '@sceneio/tools'
import { computePosition, flip, offset, shift } from '@floating-ui/dom'

export function TextFormatFloatingToolbar({
  blockType,
  editor,
  isLink,
  isBold,
  isItalic,
  isStrikethrough,
  isUnderline,
  canUndo,
  canRedo,
  fontFamily,
  fontWeight,
  textColor,
  backgroundColor,
  enableAI,
  enableHistory,
  enableTag,
  enableLink,
  enableList,
  enableFormat,
  enableFont,
  enableColor,
}: {
  blockType: keyof typeof blockTypeToBlockName
  editor: LexicalEditor
  isBold: boolean
  isItalic: boolean
  isLink: boolean
  isStrikethrough: boolean
  isUnderline: boolean
  canUndo: boolean
  canRedo: boolean
  fontFamily: string
  fontWeight: string
  textColor: string
  backgroundColor: string
  enableAI?: boolean
  enableHistory?: boolean
  enableTag?: boolean
  enableLink?: boolean
  enableList?: boolean
  enableFormat?: boolean
  enableFont?: boolean
  enableColor?: boolean
}): JSX.Element {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null)
  const lastValidRangeRef = useRef<Range | null>(null)

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const popupCharStylesEditorElem = popupCharStylesEditorRef.current
    if (!popupCharStylesEditorElem) {
      return
    }

    editor.getEditorState().read(() => {
      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()

      let range: Range | null = lastValidRangeRef.current

      if (
        nativeSelection &&
        !nativeSelection.isCollapsed &&
        rootElement?.contains(nativeSelection.anchorNode)
      ) {
        range = nativeSelection.getRangeAt(0)
        lastValidRangeRef.current = range
      }

      // Hide the toolbar if we have no valid range
      if (!range) {
        popupCharStylesEditorElem.style.opacity = '0'
        setTimeout(() => {
          popupCharStylesEditorElem.style.transform = `translate(-10000px, -10000px)`
        }, 500)
        return
      }

      const domRect = range.getBoundingClientRect()

      // Create a virtual element based on the selection
      const virtualElement = {
        getBoundingClientRect: () => domRect,
      }

      computePosition(virtualElement, popupCharStylesEditorElem, {
        placement: 'top',
        middleware: [offset(10), flip()],
      }).then(({ x, y }) => {
        popupCharStylesEditorElem.style.opacity = '1'
        popupCharStylesEditorElem.style.transform = `translate(${x}px, ${y}px)`
      })
    })
  }, [editor])

  function mouseMoveListener(e: MouseEvent) {
    if (
      popupCharStylesEditorRef?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX
        const y = e.clientY
        const elementUnderMouse = document.elementFromPoint(x, y)

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none'
        }
      }
    }
  }
  function mouseUpListener() {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto'
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener)
      document.addEventListener('mouseup', mouseUpListener)

      // Get the infinite viewer element
      const rootElement = editor.getRootElement()
      const canvasElem = rootElement?.closest('.viewport') as HTMLElement
      const infiniteViewer = canvasElem?.closest(
        '.infinite-viewer',
      ) as HTMLElement

      const debouncedUpdate = debounce(updateTextFormatFloatingToolbar, 4)

      if (infiniteViewer) {
        // Update position on scroll
        infiniteViewer.addEventListener('scroll', debouncedUpdate)
        // Update position on zoom/pinch
        infiniteViewer.addEventListener('wheel', debouncedUpdate, {
          passive: true,
        })
        infiniteViewer.addEventListener('pinch', debouncedUpdate)
        // Update position on drag
        infiniteViewer.addEventListener('drag', debouncedUpdate)
      }

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener)
        document.removeEventListener('mouseup', mouseUpListener)
        if (infiniteViewer) {
          infiniteViewer.removeEventListener('scroll', debouncedUpdate)
          infiniteViewer.removeEventListener('wheel', debouncedUpdate)
          infiniteViewer.removeEventListener('pinch', debouncedUpdate)
          infiniteViewer.removeEventListener('drag', debouncedUpdate)
        }
      }
    }
    return
  }, [popupCharStylesEditorRef, updateTextFormatFloatingToolbar, editor])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor, updateTextFormatFloatingToolbar])

  return (
    <Toolbar.Root
      ref={popupCharStylesEditorRef}
      className="wysiwyg-toolbar-root"
      aria-label="Formatting options"
      data-no-drag
    >
      {editor.isEditable() && (
        <>
          {enableHistory && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Toolbar.ToggleGroup
                className="wysiwyg-toolbar-toggle-group"
                type="multiple"
                aria-label="Text history"
              >
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="back"
                  aria-label="Back"
                  disabled={!canUndo}
                  onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, undefined)
                  }}
                >
                  <Icon
                    weight="bold"
                    size={16}
                    provider="phosphor"
                    icon="ArrowArcLeft"
                  />
                </Toolbar.ToggleItem>
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="forward"
                  aria-label="Forward"
                  disabled={!canRedo}
                  onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, undefined)
                  }}
                >
                  <Icon
                    weight="bold"
                    size={16}
                    provider="phosphor"
                    icon="ArrowArcRight"
                  />
                </Toolbar.ToggleItem>
              </Toolbar.ToggleGroup>
            </>
          )}
          {enableTag && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <ToolbarTagSelect
                editor={editor}
                blockType={blockType}
                enableList={enableList}
              />
            </>
          )}
          {enableFont && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <ToolbarFontSelect editor={editor} fontFamily={fontFamily} />
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <ToolbarFontWeightSelect
                editor={editor}
                fontWeight={fontWeight}
                fontFamily={fontFamily}
              />
            </>
          )}
          {enableColor && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <ToolbarColorPicker
                editor={editor}
                textColor={textColor}
                textBackgroundColor={backgroundColor}
              />
            </>
          )}
          {enableFormat && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Toolbar.ToggleGroup
                className="wysiwyg-toolbar-toggle-group"
                type="multiple"
                aria-label="Text formatting"
              >
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="bold"
                  aria-label="Bold"
                  data-state={isBold ? 'on' : 'off'}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
                  }}
                >
                  <Icon
                    weight="bold"
                    size={16}
                    provider="phosphor"
                    icon="TextB"
                  />
                </Toolbar.ToggleItem>
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="italic"
                  aria-label="Italic"
                  data-state={isItalic ? 'on' : 'off'}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
                  }}
                >
                  <Icon
                    weight="bold"
                    size={16}
                    provider="phosphor"
                    icon="TextItalic"
                  />
                </Toolbar.ToggleItem>
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="underline"
                  aria-label="Underline"
                  data-state={isUnderline ? 'on' : 'off'}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
                  }}
                >
                  <Icon
                    weight="bold"
                    size={16}
                    provider="phosphor"
                    icon="TextUnderline"
                  />
                </Toolbar.ToggleItem>
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="strikethrough"
                  aria-label="Strike through"
                  data-state={isStrikethrough ? 'on' : 'off'}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
                  }}
                >
                  <Icon
                    weight="bold"
                    size={16}
                    provider="phosphor"
                    icon="TextStrikethrough"
                  />
                </Toolbar.ToggleItem>
              </Toolbar.ToggleGroup>
            </>
          )}
          {enableLink && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Toolbar.ToggleGroup
                className="wysiwyg-toolbar-toggle-group"
                type="multiple"
                aria-label="Text links"
              >
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="text-link"
                  aria-label="Text Link"
                  data-state={isLink ? 'on' : 'off'}
                  onClick={insertLink}
                >
                  <Icon
                    weight="bold"
                    size={16}
                    provider="phosphor"
                    icon="LinkSimple"
                  />
                </Toolbar.ToggleItem>
              </Toolbar.ToggleGroup>
            </>
          )}
          {enableAI && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Toolbar.ToggleGroup
                className="wysiwyg-toolbar-toggle-group"
                type="multiple"
                aria-label="Muse AI"
              >
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value=""
                  aria-label="Muse AI"
                  data-state={'off'}
                  onClick={() =>
                    document
                      .querySelector('.react-chatbot-kit-chat-container')
                      ?.classList.add('expanded')
                  }
                >
                  <Icon
                    weight="bold"
                    size={16}
                    provider="scene"
                    icon="sparkle"
                  />
                </Toolbar.ToggleItem>
              </Toolbar.ToggleGroup>
            </>
          )}
        </>
      )}
    </Toolbar.Root>
  )
}
