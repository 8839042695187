import { InferType, bool, mixed, object, string } from 'yup'
import { reactNodeSchema } from '../shared/reactNodeSchema'
import { styleSchema } from '../shared/styleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { cssPositionSchema } from '../styles/common/cssPositionSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { minHeightStyleSchema } from '../styles/minHeightStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { blockContainerComponentSchema } from './blockContainerComponentSchema'

import { blockEditorSchema } from '../shared/blockEditorSchema'

export const blockComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['block-component']).required(),
  })
    .noUnknown()
    .required(),
  children: reactNodeSchema,
  minHeight: minHeightStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  className: string(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  as: string().oneOf(['section', 'article', 'footer', 'header']),
  border: borderStyleSchema.optional(),
  dataAttributes: object().optional(),
  id: string(),
  anchorId: string(),
  style: styleSchema,
  show: bool(),
  background: backgroundSchema.optional(),
  editor: blockEditorSchema,
  blockContainer: blockContainerComponentSchema.optional(),
  beforeContainerEl: mixed<any>(),
}).noUnknown()

export type BlockComponent = InferType<
  typeof blockComponentSchema & {
    as?: keyof JSX.IntrinsicElements
  }
>

export type BlockComponentStyles = Pick<
  BlockComponent,
  | 'minHeight'
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'border'
>
