import { InferType, bool, object, string } from 'yup'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { styleSchema } from '../shared/styleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { containerComponentSchema } from './containerComponentSchema'
import { textComponentSchema } from './textComponentSchema'
import { widthStyleSchema } from '../styles/widthStyleSchema'

const countdownItemComponentSchema = containerComponentSchema.shape({
  components: object({
    text: textComponentSchema,
    label: textComponentSchema,
  }).noUnknown(),
})

export const countdownComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['countdown-component']).required(),
  })
    .noUnknown()
    .required(),
  selectedDate: string(),
  components: object({
    days: countdownItemComponentSchema,
    hours: countdownItemComponentSchema,
    minutes: countdownItemComponentSchema,
    seconds: countdownItemComponentSchema,
    dots: textComponentSchema,
  }).noUnknown(),
  className: string(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  background: backgroundSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  width: widthStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  cid: string(),
  id: string(),
  preview: bool(),
}).noUnknown()

export type CountdownComponent = InferType<typeof countdownComponentSchema>
export type CountdownItemComponentSchema = InferType<
  typeof countdownItemComponentSchema
>

export type CountdownComponentStyles = Pick<
  CountdownComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'border'
  | 'gap'
>
