import React from 'react'
import clsx from 'clsx'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { FormComponent } from '@sceneio/schemas/lib/types'
import Text from '../Text/Text'
import Button from '../Button/Button'
import FormField from '../FormField/FormField'
import { getContainerFlowClasses } from '../Container/containerHelpers'
import { stringify } from '@sceneio/tools'
import { resolveBreakpointImageClasses } from '../Image/helpers/resolveBreakpointImageClasses'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { BreakpointEnum } from '../Image/constants/breakpoints'
import { resolveDefaultImageClasses } from '../Image/helpers/resolveDefaultImageClasses'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'

function Form({
  items: formFields = [],
  components: { button, successMessage, errorMessage },
  settings,
  padding,
  margin,
  flow,
  border,
  boxShadow,
  background,
  horizontalAlignment,
  verticalAlignment,
  gap,
  style,
  className,
  show = true,
  editor,
  preview,
  cid,
}: FormComponent) {
  if (!show) {
    return null
  }

  const { provider = 'formspark', formId } = settings || {}

  const formClass = clsx(
    'sc-form-component',
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    { 'sc-form-component--preview': preview },
    className,
    getContainerFlowClasses({ flow, className: 'sc-form-component' }),
  )

  const {
    fit,
    parallax,
    responsiveConfig: bgResponsiveConfig,
  } = background || {}

  return (
    <form
      autoComplete="off"
      data-component-id={editor?.id}
      data-plugin-form={stringify({
        provider,
        data: {
          formId: formId,
        },
      })}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            padding,
            margin,
            flow,
            gap,
            border,
            horizontalAlignment,
            verticalAlignment,
          },
          prefix: 'form',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'form',
        }),
        ...getBackgroundCssVariables({
          background,
          prefix: 'form',
        }),
      }}
      className={formClass}
    >
      <div className="sc-form-component__fields">
        {formFields.map((field, idx) => (
          <FormField
            key={field.id}
            {...field}
            editor={{ ...editor, id: `${editor?.id}.items[${idx}]` }}
            preview={preview}
            cid={`${cid}-${field.id}`}
          />
        ))}
        <Button
          {...button}
          className="sc-form-component__submit-button--row"
          dataAttributes={{
            'data-plugin-form-submit-button': true,
          }}
          editor={{
            ...editor,
            id: `${editor?.id}.button`,
            wysiwyg: {
              type: 'TEXT',
            },
          }}
        />
      </div>
      <Text
        dataAttributes={{
          'data-plugin-form-success-message': true,
        }}
        {...successMessage}
        editor={{
          ...editor,
          id: `${editor?.id}.successMessage`,
        }}
        className="sc-form-component__success-message"
        show={preview ? successMessage.show : true}
      />
      <Text
        dataAttributes={{
          'data-plugin-form-error-message': true,
        }}
        {...errorMessage}
        editor={{
          ...editor,
          id: `${editor?.id}.errorMessage`,
        }}
        className="sc-form-component__error-message"
        show={preview ? errorMessage.show : true}
      />

      <Button
        {...button}
        dataAttributes={{
          'data-plugin-form-submit-button': true,
        }}
        className="sc-form-component__submit-button--col"
        editor={{
          ...editor,
          id: `${editor?.id}.button`,
          wysiwyg: {
            type: 'TEXT',
          },
        }}
      />
      <div className="sc-form-component__bg-wrapper">
        {bgResponsiveConfig && Object.keys(bgResponsiveConfig).length && (
          <>
            <div className="sc-form-component__bg-overlay" />
            {Object.entries(bgResponsiveConfig).map(([bp, config]) => {
              const { image } = config || {}
              return (
                <React.Fragment key={bp}>
                  {image?.src && (
                    <div
                      style={{
                        backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                      }}
                      data-twic-background={`url(${twicPicsSourceUrlResolver(
                        image.src,
                      )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                      className={clsx('sc-form-component__bg-image', {
                        'sc-form-component__bg-image--parallax': parallax,
                        'sc-form-component__bg-image--cover': fit === 'cover',
                        [resolveBreakpointImageClasses({
                          breakpoint: bp as BreakpointEnum,
                          elementClassName: 'sc-form-component__bg-image',
                        })]: bp !== 'default',
                        [resolveDefaultImageClasses({
                          breakpointsArr: (
                            Object.keys(bgResponsiveConfig) as BreakpointEnum[]
                          ).filter((bp) => bgResponsiveConfig[bp]),
                          elementClassName: 'sc-form-component__bg-image',
                        })]: bp === 'default',
                      })}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </>
        )}
      </div>
    </form>
  )
}

export const plugins: PluginsType = {
  js: ['form'],
  css: [],
}

export default Form
