const VIDEO_PLATFORM_MAP = [
  {
    platform: 'cloudinary',
    regular: /^.+\.cloudinary/i,
    getVideoId: (url: string = '') => {
      const regex = new RegExp(/(?:.+\/)(.+(?=\.)?)/)
      if (regex.test(url)) {
        const [, fileName] = url.match(regex) || []
        // trim file extension if exists
        return fileName.replace(/\.[^/.]+$/, '')
      }
      return 'undefined'
    },
  },
  {
    platform: 'scene-assets',
    regular: /^.+\.amazonaws/i,
    getVideoId: (url: string = '') => {
      const regex = new RegExp(/(?:.+\/)(.+(?=\.)?)/)
      if (regex.test(url)) {
        const [, fileName] = url.match(regex) || []
        // trim file extension if exists
        return fileName.replace(/\.[^/.]+$/, '')
      }
      return 'undefined'
    },
  },
  {
    platform: 'user-asset',
    regular: /^.+\/cdn\.(scene\.io|scene-io\.dev)/i,
    getVideoId: (url: string = '') => {
      const regex = new RegExp(/(?:.+\/)(.+(?=\.)?)/)
      if (regex.test(url)) {
        const [, fileName] = url.match(regex) || []
        // trim file extension if exists
        return fileName.replace(/\.[^/.]+$/, '')
      }
      return 'undefined'
    },
  },
  {
    platform: 'vimeo',
    regular: /vimeo/i,
    getVideoId: (url: string = '') => {
      const regex = new RegExp(/\/(\d+)\/?/)
      if (regex.test(url)) {
        return (url.match(regex) || ['', ''])[1]
      }

      return 'undefined'
    },
  },
  {
    platform: 'youtube',
    regular: /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/i,
    getVideoId: (url: string = '') => {
      const regex = new RegExp(/^.*(\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/)
      if (regex.test(url)) {
        return (url.match(regex) || ['', ''])[2]
      }
      return 'undefined'
    },
  },
  {
    platform: 'wistia',
    regular: /wistia/i,
    getVideoId: (url: string = '') => {
      const regex = new RegExp(/[^/]+$/)
      if (regex.test(url)) {
        return (url.match(regex) || [''])[0]
      }
      return 'undefined'
    },
  },
]

export function getVideoInfoFromUrl(url: string = '') {
  if (!url) throw Error('Invalid url given')

  const resolvedVideoPlatform = VIDEO_PLATFORM_MAP.find(({ regular }) =>
    regular.test(url),
  )

  if (!resolvedVideoPlatform) throw Error('Unsupported video platform')

  const { platform, getVideoId } = resolvedVideoPlatform

  return {
    platform,
    id: getVideoId(url),
  }
}

export function getVideoPlatformFromUrl(url: string = '') {
  if (!url) {
    console.error('Invalid url given')
  }

  const { platform } =
    VIDEO_PLATFORM_MAP.find(({ regular }) => regular.test(url)) || {}

  if (!platform) {
    console.error('Unsupported video platform')
  }

  return platform
}
