import React from 'react'
import clsx from 'clsx'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import Button from '../Button/Button'
import { Container } from '../Container/Container'
import Media from '../Media/Media'
import Text from '../Text/Text'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { PricingCardComponent } from '@sceneio/schemas/lib/types'
import { editorItemsControlsFactory } from '../../tools/editor/editor'
import { buttonSampleFactory } from './pricingCardMeta'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { resolveBreakpointImageClasses } from '../Image/helpers/resolveBreakpointImageClasses'
import {
  AvailableBreakpointsEnum,
  BreakpointEnum,
} from '../Image/constants/breakpoints'
import { resolveDefaultImageClasses } from '../Image/helpers/resolveDefaultImageClasses'
import IconComponent from '../Icon/Icon'

function PricingCard({
  direction = 'vertical',
  swapOrder = false,
  gap,
  horizontalAlignment,
  verticalAlignment,
  padding,
  margin,
  boxShadow,
  border,
  background,
  components: {
    media,
    badge,
    contentContainer,
    tagline,
    heading,
    description,
    buttons,
    subheading,
    checkList,
  },
  cid,
  style,
  editor,
  className,
  preview,
  isAnimated,
}: PricingCardComponent) {
  const pricingCardClasses = clsx(
    'sc-card sc-pricing-card',
    {
      'sc-card--vertical': direction === 'vertical',
      'sc-card--horizontal': direction === 'horizontal',
      'sc-card--swap-order': swapOrder,
    },
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    className,
  )

  const {
    fit,
    parallax,
    responsiveConfig: bgResponsiveConfig,
  } = background || {}

  return (
    <div
      data-component-id={editor?.id}
      className={pricingCardClasses}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            horizontalAlignment,
            verticalAlignment,
            gap,
            padding,
            margin,
            border,
          },
          prefix: 'card',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'card',
        }),
        ...getBackgroundCssVariables({
          background,
          prefix: 'card',
        }),
      }}
    >
      <div className="sc-card__media-col">
        <div className="bui-d-flex bui-flex-wrap bui-align-items-center bui-justify-content-between bui-h-100">
          <Media
            {...media}
            editor={{ ...editor, id: `${editor?.id}.media` }}
            cid={resolveComponentId(cid, 'media')}
            preview={preview}
            components={{
              ...media.components,
              image: {
                ...media.components.image,
                meta: {
                  dataType: 'image-component',
                },
                isAnimated,
              },
            }}
          />
          {direction === 'vertical' && (
            <Container
              editor={{
                ...editor,
                id: `${editor?.id}.badge`,
              }}
              {...badge}
              name="component"
            >
              <Text
                {...badge.components.text}
                editor={{
                  ...editor,
                  id: `${editor?.id}.badge.text`,
                }}
                cid={resolveComponentId(cid, 'badgeText')}
              />
            </Container>
          )}
        </div>
      </div>
      <div className="sc-card__content-col">
        <Container
          {...contentContainer}
          name="group"
          editor={{
            ...editor,
            id: `${editor?.id}.contentContainer`,
          }}
          flow={{ data: { '--flow-default': 'column' } }}
        >
          {direction === 'horizontal' && (
            <Container
              editor={{
                ...editor,
                id: `${editor?.id}.badge`,
              }}
              {...badge}
              name="component"
            >
              <Text
                {...badge.components.text}
                editor={{
                  ...editor,
                  id: `${editor?.id}.badge.text`,
                }}
                cid={resolveComponentId(cid, 'badgeText')}
                isAnimated={isAnimated}
              />
            </Container>
          )}
          <Text
            {...tagline}
            editor={{
              ...editor,
              id: `${editor?.id}.tagline`,
            }}
            cid={resolveComponentId(cid, 'tagline')}
            isAnimated={isAnimated}
          />
          <Text
            {...heading}
            editor={{
              ...editor,
              id: `${editor?.id}.heading`,
            }}
            cid={resolveComponentId(cid, 'heading')}
            isAnimated={isAnimated}
          />
          <Text
            {...description}
            editor={{
              ...editor,
              id: `${editor?.id}.description`,
            }}
            cid={resolveComponentId(cid, 'description')}
            isAnimated={isAnimated}
          />
          <Container
            className="bui-flex-wrap bui-w-100"
            editor={{
              ...editor,
              id: `${editor?.id}.buttons`,
            }}
            {...buttons}
            name="componentArray"
          >
            {buttons?.items?.map(
              (
                {
                  id: buttonId,
                  childrenWrapProps = {},
                  className: buttonClassname,
                  ...restButtonProps
                },
                index,
              ) => (
                <Button
                  editor={{
                    ...editor,
                    id: `${editor?.id}.buttons.items[${index}]`,
                    wysiwyg: {
                      type: 'ITEMS',
                      ...editorItemsControlsFactory({
                        itemsArr: buttons.items || [],
                        itemsId: `${editor?.id}.buttons.items`,
                        itemFactory: buttonSampleFactory,
                        index: index,
                      }),
                    },
                  }}
                  className={clsx('sc-card__button', buttonClassname)}
                  childrenWrapProps={{
                    ...childrenWrapProps,
                  }}
                  key={buttonId}
                  cid={resolveComponentId(cid, `button-${index}`)}
                  isAnimated={isAnimated}
                  {...restButtonProps}
                />
              ),
            )}
          </Container>
          <Text
            {...subheading}
            editor={{
              ...editor,
              id: `${editor?.id}.subheading`,
            }}
            cid={resolveComponentId(cid, 'subheading')}
            isAnimated={isAnimated}
          />
          <Container
            editor={{
              ...editor,
              id: `${editor?.id}.checkList`,
            }}
            {...checkList}
            name="componentArray"
          >
            {checkList?.items?.map(
              (
                {
                  id: itemId,
                  className: itemClassname,
                  components,
                  ...restItemProps
                },
                index,
              ) => (
                <Container
                  key={itemId}
                  name="component"
                  className={clsx('sc-pricing-card__check-item', itemClassname)}
                  {...restItemProps}
                  editor={{
                    ...editor,
                    id: `${editor?.id}.checkList.items[${index}]`,
                    wysiwyg: {
                      type: 'ITEMS',
                      ...editorItemsControlsFactory({
                        itemsArr: buttons.items || [],
                        itemsId: `${editor?.id}.checkList.items`,
                        itemFactory: buttonSampleFactory,
                        index: index,
                      }),
                    },
                  }}
                >
                  <>
                    {components.icon?.show && (
                      <div
                        data-component-id={`${editor?.id}.checkList.items[${index}].icon`}
                        className={clsx(
                          'bui-d-flex',
                          editor?.resolveClassName?.({
                            blockId: editor.blockId,
                            componentId: `${editor?.id}.checkList.items[${index}].icon`,
                          }),
                        )}
                      >
                        <IconComponent
                          {...components.icon}
                          className="sc-pricing-card__icon"
                        />
                      </div>
                    )}
                  </>
                  <Text
                    {...components.text}
                    editor={{
                      ...editor,
                      id: `${editor?.id}.checkList.items[${index}].text`,
                    }}
                    cid={resolveComponentId(cid, `checkList-text-${index}`)}
                    isAnimated={isAnimated}
                  />
                </Container>
              ),
            )}
          </Container>
        </Container>
      </div>

      <div className="sc-card__bg-wrapper">
        {bgResponsiveConfig && Object.keys(bgResponsiveConfig).length && (
          <>
            <div className="sc-card__bg-overlay" />
            {Object.entries(bgResponsiveConfig).map(([bp, config]) => {
              const { image } = config || {}
              return (
                <React.Fragment key={bp}>
                  {image?.src && (
                    <div
                      style={{
                        backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                      }}
                      data-twic-background={`url(${twicPicsSourceUrlResolver(
                        image.src,
                      )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                      className={clsx('sc-card__bg-image', {
                        'sc-card__bg-image--parallax': parallax,
                        'sc-card__bg-image--cover': fit === 'cover',
                        [resolveBreakpointImageClasses({
                          breakpoint: bp as BreakpointEnum,
                          elementClassName: 'sc-card__bg-image',
                        })]: bp !== 'default',
                        [resolveDefaultImageClasses({
                          breakpointsArr: (
                            Object.keys(
                              bgResponsiveConfig,
                            ) as AvailableBreakpointsEnum[]
                          ).filter((bp) => bgResponsiveConfig[bp]),
                          elementClassName: 'sc-card__bg-image',
                        })]: bp === 'default',
                      })}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}

export const plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default PricingCard
