import * as Select from '@radix-ui/react-select'
import { ReactNode } from 'react'
import clsx from 'clsx'

interface TagSelectItemProps {
  value: string
  children: ReactNode
  active?: boolean
}

export function TagSelectItem({ value, children, active }: TagSelectItemProps) {
  return (
    <Select.Item
      value={value}
      className={clsx('wysiwyg-tag-select-item', {
        'wysiwyg-tag-select-item--active': active,
      })}
    >
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  )
}
